<template>
  <ez-header
    :service-name="$t('header.service-name')"
    @logoClick="onLogoClick"
  >
    <!-- Slot title -->
    <template slot="title">
      <ez-link
        v-for="(item, index) in breadCrumbs"
        :key="index"
        :href="item.href"
        :beRouterLink="true"
        :active="item.active"
      >
        <span v-text="item.name"></span>
      </ez-link>
      <ez-badge
        v-if="isContactPage && currentContactSubscriptionStatus"
        :type="currentContactSubscriptionStatus.type"
        >{{ currentContactSubscriptionStatus.title }}</ez-badge
      >
    </template>

    <template slot="controls">
      <ez-button-group v-if="currentContact != null && isContactPage">
        <ez-button icon="trash" @click="preDeleteContact"></ez-button>
        <ez-button
          v-if="
            currentContact &&
              currentContact.subscribeStatus !=
                subscriptionStatus.SUBSCRIBE_STATUS_CONFIRMED
          "
          icon="user"
          :disabled="updatingUser"
          :preloader="updatingUser"
          @click="
            onPreChangeSubscribeStatus(
              subscriptionStatus.SUBSCRIBE_STATUS_CONFIRMED
            )
          "
          type="primary"
          >{{ $t("header.sub-button") }}</ez-button
        >
        <ez-button
          v-if="
            currentContact &&
              currentContact.subscribeStatus ===
                subscriptionStatus.SUBSCRIBE_STATUS_CONFIRMED
          "
          icon="user-slash"
          type="warning"
          :disabled="updatingUser"
          :preloader="updatingUser"
          @click="
            onPreChangeSubscribeStatus(
              subscriptionStatus.SUBSCRIBE_STATUS_UNSUBSCRIBED
            )
          "
          >{{ $t("header.unsub-button") }}</ez-button
        >
      </ez-button-group>
    </template>

    <template slot="tabs">
      <ez-tabs
        :options="navItems"
        @click="onClickTabs"
        v-if="isContactPage"
      ></ez-tabs>
    </template>

    <template slot="user">
      <ez-dropdown-user
        :options="users"
        :selected="selectedUserId"
        @user-selected="onUserSelected"
        @logout="onLogout"
      ></ez-dropdown-user>

      <div class="dialogs" v-if="isContactPage && currentContact">
        <ez-dialog :open.sync="dialogDeleteOpen">
          <template slot="header">
            <ez-icon size="20" name="edit" color="#555555" />
            {{ $t("header.remove-dialog-header") }}
          </template>
          <template>
            {{ removeDialogBody }}
          </template>
          <template slot="footer">
            <ez-button-group justify="end">
              <ez-button @click="dialogDeleteOpen = false" type="secondary">
                {{ $t("cancel-button") }}
              </ez-button>
              <ez-button @click="deleteContact" type="danger">
                {{ $t("delete-button") }}
              </ez-button>
            </ez-button-group>
          </template>
        </ez-dialog>

        <ez-dialog :open.sync="dialogSubscribeOpen">
          <template slot="header">
            <ez-icon size="20" name="user" color="#555555" />
            {{ $t("header.update-sub-status-dialog-header") }}
          </template>
          <template>
            {{
              $t("header.update-sub-status-dialog-body", {
                newStatus: newSubscriptionStatusLabel,
                contactName
              })
            }}
          </template>
          <template slot="footer">
            <ez-button-group justify="end">
              <ez-button @click="dialogSubscribeOpen = false" type="secondary">
                {{ $t("cancel-button") }}
              </ez-button>
              <ez-button @click="onChangeSubscribeStatus" type="primary">
                {{ $t("yes-button") }}
              </ez-button>
            </ez-button-group>
          </template>
        </ez-dialog>
      </div>
    </template>
  </ez-header>
</template>

<script>
import { mapGetters } from 'vuex';
import * as routerNames from '../router/names';
import * as authGetters from '../store/auth/getters';
import * as authActions from '../store/auth/actions';
import * as navGetters from '../store/nav/getters';
import * as contactsGetters from '../store/contacts/getters';
import * as contactsActions from '../store/contacts/actions';
import * as injectNames from '../services/names';
import * as subscriptionStatus from '../store/contacts/subscriptionStatus';

export default {
  dependencies: [
    injectNames.CONTACTS_API,
    '$log',
  ],
  name: 'appHeader',
  data() {
    return {
      subscriptionStatus,

      dialogDeleteOpen: false,

      dialogSubscribeOpen: false,
      newSubscriptionStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/IS_LOGGED_IN',
    }),
    navItems() {
      const { name } = this.$route;
      return this.navItemsData.map((item) => ({
        ...item,
        active: name === item.params,
      }));
    },
    users() {
      const users = this.$store.getters[`auth/${authGetters.AVAILABLE_USERS}`];
      const avatars = this.$store.getters[`auth/${authGetters.AVATAR_URLS}`];
      if (users && users.length > 0) {
        return users.map((user) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`.trim(),
          email: user.email,
          image: avatars[user.avatar] || '',
        }));
      }
      return [];
    },
    selectedUser() {
      const user = this.$store.getters[`auth/${authGetters.CURRENT_USER}`];
      return user;
    },
    selectedUserId() {
      return this.selectedUser ? this.selectedUser.id : null;
    },
    currentContact() {
      const data = this.$store.getters[
        `contacts/${contactsGetters.CURRENT_ITEM}`
      ];
      return data;
    },
    currentContactSubscriptionStatus() {
      if (!this.currentContact) {
        return null;
      }
      switch (this.currentContact.subscribeStatus) {
        case subscriptionStatus.SUBSCRIBE_STATUS_PENDING:
          return {
            type: 'warning',
            title: this.$t('header.contact-sub-status.pending'),
          };
        case subscriptionStatus.SUBSCRIBE_STATUS_UNSUBSCRIBED:
          return {
            type: 'danger',
            title: this.$t('header.contact-sub-status.unsubscribed'),
          };
        case subscriptionStatus.SUBSCRIBE_STATUS_CONFIRMED:
          return {
            type: 'success',
            title: this.$t('header.contact-sub-status.subscribed'),
          };
        default:
          return null;
      }
    },
    newSubscriptionStatusLabel() {
      if (!this.newSubscriptionStatus) {
        return '';
      }
      switch (this.newSubscriptionStatus) {
        case subscriptionStatus.SUBSCRIBE_STATUS_PENDING:
        case subscriptionStatus.SUBSCRIBE_STATUS_UNSUBSCRIBED:
          return this.$t(
            'header.update-sub-status-dialog-new-status.unsubscribe',
          );
        case subscriptionStatus.SUBSCRIBE_STATUS_CONFIRMED:
          return this.$t(
            'header.update-sub-status-dialog-new-status.subscribe',
          );
        default:
          return '';
      }
    },
    isContactPage() {
      const isContactPage = this.$route.meta.isContactPage === true;
      return isContactPage;
    },
    breadCrumbs() {
      const items = this.$store.getters[`nav/${navGetters.BREADCRUMBS}`];
      return items;
    },
    updatingUser() {
      const data = this.$store.getters[`contacts/${contactsGetters.UPDATING}`];
      return data;
    },
    serviceLimits() {
      return this.$store.getters[`auth/${authGetters.LIMITS}`].filter(
        (item) => item.value != null,
      );
    },

    removeDialogBody() {
      if (this.currentContact) {
        return this.$t('header.remove-dialog-body', {
          contactName: this.contactName,
        });
      }
      return '';
    },

    contactName() {
      if (!this.currentContact) { return ''; }
      const { firstName, lastName, email } = this.currentContact;
      return firstName || lastName
        ? `${firstName || ''} ${lastName || ''}`.trim()
        : email;
    },

    currentTariffName() {
      return this.$store.getters[`auth/${authGetters.CURRENT_TARIFF}`];
    },

    navItemsData() {
      return [
        {
          name: this.$t('header.menuLinks.overview'),
          params: routerNames.OVERVIEW,
        },
        {
          name: this.$t('header.menuLinks.timeline'),
          params: routerNames.TIMELINE,
        },
        {
          name: this.$t('header.menuLinks.activity'),
          params: routerNames.ACTIVITY,
        },
        {
          name: this.$t('header.menuLinks.purchases'),
          params: routerNames.PURCHASES,
        },
        {
          name: this.$t('header.menuLinks.funnels'),
          params: routerNames.FUNNELS,
        },
        {
          name: this.$t('header.menuLinks.membership'),
          params: routerNames.MEMBERSHIP,
        },
        {
          name: this.$t('header.menuLinks.webinars'),
          params: routerNames.WEBINARS,
        },
      ];
    },
  },
  methods: {
    escape(input) {
      return `${(input || '').replace('>', '&gt;').replace('>', '&gt;')}`;
    },
    onClickTabs(item) {
      const { params } = item;
      this.$router.push({ name: params });
    },
    preDeleteContact() {
      this.dialogDeleteOpen = true;
    },
    deleteContact() {
      this.dialogDeleteOpen = false;
      this[injectNames.CONTACTS_API]
        .deleteContacts([this.currentContact.id])
        .then(() => {
          this.$router.push({ name: 'dashboard' });
        });
    },
    onLogoClick() {
      // eslint-disable-next-line
      this.$router.push({ name: 'dashboard' }).catch(err => {});
    },
    onPreChangeSubscribeStatus(status) {
      this.dialogSubscribeOpen = true;
      this.newSubscriptionStatus = status;
    },
    onChangeSubscribeStatus() {
      this.dialogSubscribeOpen = false;

      const status = this.newSubscriptionStatus;

      const item = {
        id: this.currentContact.id,
        subscribeStatus: status,
      };

      this.$store.dispatch(`contacts/${contactsActions.UPDATE_ITEM}`, {
        item,
        setAsCurrent: true,
        tagsToAttach: [],
        tagsToDetach: [],
      });
    },

    onUserSelected(user) {
      this.$store.dispatch(`auth/${authActions.CHANGE_USER}`, {
        user,
        router: this.$router,
      });
    },

    onLogout() {
      this.$store.dispatch(`auth/${authActions.LOGOUT}`);
    },
  },
};
</script>
