import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

export default (raw) => {
  const { included = [], data = [] } = raw;

  const deps = keyBy(included, 'id');

  const items = !isArray(data)
    ? []
    : data.map((item) => {
      const { relationships = {} } = item;
      const keys = Object.keys(relationships);
      const tmp = reduce(
        keys,
        (result, key) => {
          const rel = relationships[key];
          if (!isArray(rel.data)) {
            const id = get(rel, 'data.id', null);
            if (id != null) {
              result[key] = deps[id];
            }
          } else {
            const rels = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const row of rel.data) {
              const { id } = row || {};
              if (id != null && deps[id]) {
                rels.push(deps[id]);
              }
              result[key] = rels;
            }
          }
          return result;
        },
        {},
      );

      return {
        id: item.id,
        attributes: item.attributes,
        type: item.type,
        relationships: tmp,
      };
    });

  const result = {
    items,
    meta: raw.meta,
    deps,
    raw,
  };

  return result;
};
