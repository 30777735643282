export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
export const SET_LOADING = 'SET_LOADING';
export const SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_DATA = 'SET_DATA';

const mutations = {
  [SET_CURRENT_ITEM]: (state, item) => {
    state.currentItem = item;
  },
  [SET_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [SET_TOTAL_ITEMS]: (state, count) => {
    state.totalItems = count;
  },
  [SET_TOTAL_PAGES]: (state, count) => {
    state.totalPages = count;
  },
  [SET_PAGE_SIZE]: (state, size) => {
    state.pageSize = size;
  },
  [SET_PAGE_NUMBER]: (state, pageNumber) => {
    state.pageNumber = pageNumber;
  },
  [SET_DATA]: (state, data) => {
    state.data = data;
  },
};

export default mutations;
