export default Object.freeze([{
  index: 1,
  text: 'EUR',
  symbol: '€',
},
{
  index: 2,
  text: 'USD',
  symbol: '$',
},
{
  index: 3,
  text: 'CHF',
  symbol: '₣',
}]);
