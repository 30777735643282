import store from '@/store';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

export default (value, options) => {
  const { currentUser } = store.state.auth;
  const { timeZone } = currentUser || {};
  const { timeFormat = 'YYYY-MM-DD HH:mm:ss' } = options || {};
  const date = timeZone
    ? moment.utc(value).tz(timeZone)
    : moment.utc(value);
  return date.isValid()
    ? date.format(timeFormat)
    : null;
};
