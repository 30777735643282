import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import contacts from './contacts';
import nav from './nav';
import tags from './tags';
import pageActivity from './page-activity';
import timeline from './timeline';
import funnels from './funnels';
import webinars from './webinars';
import purchases from './purchases';
import academies from './academies';

// temp modules
import contacts_ from './contacts_';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    contacts,
    auth,
    nav,
    tags,
    pageActivity,
    timeline,
    funnels,
    webinars,
    purchases,
    academies,

    // TODO: remove temp module
    contacts_,
  },
});
