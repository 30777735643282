import injector from 'vue-inject';
import Vue from 'vue';

import './constants';
import './apiUser';
import './apiContacts';
import './apiTags';
import './apiPageActivity';
import './apiTimeline';
import './apiFunnels';
import './apiWebinars';
import './apiPurchases';
import './apiAcademies';
import './fileDownloader';
import './localSettings';

Vue.use(injector);

export default injector;
