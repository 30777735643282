export const DATA_TYPE = 'tags';

const fromJsonApi = (data) => {
  const { attributes, id } = data;

  return {
    id: id || attributes.id,
    name: attributes.name,
    dtCreate: attributes['dt-create'],
    dtUpdate: attributes['dt-update'],
  };
};

const toJsonApi = (item) => {
  const { id } = item;

  const attributes = {
    name: item.name,
    'dt-create': item.dtCreate,
    'dt-update': item.dtUpdate,
  };

  const newAttributes = {};
  Object.entries(attributes).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      newAttributes[key] = value;
    }
  });

  return {
    data: Object.assign(id ? { id } : {}, {
      attributes: newAttributes,
      type: DATA_TYPE,
      relationships:
        item.user != null
          ? {
            user: {
              data: {
                type: 'user',
                id: item.user,
              },
            },
          }
          : undefined,
    }),
  };
};

export default {
  fromJsonApi,
  toJsonApi,
};
