import Vue from 'vue';
import max from 'lodash/max';
import injector from 'vue-inject';
import * as injectNames from '@/services/names';

import { types } from '@/services/formatters/contactType';
import * as mutations from './mutations';

export const GET_ITEMS = 'GET_ITEMS';
export const GET_ITEM_BY_ID = 'GET_ITEM_BY_ID';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const SAVE_FIELDS = 'SAVE_FIELDS';
export const CREATE_EXPORT = 'CREATE_EXPORT';
export const CHECK_EXPORT = 'CHECK_EXPORT';
export const LOAD_WIDGETS = 'LOAD_WIDGETS';
export const LOAD_USER_WIDGETS = 'LOAD_USER_WIDGETS';
export const CHANGE_WIDGETS = 'CHANGE_WIDGETS';

const actions = {
  [GET_ITEMS]: async (
    { commit, rootGetters },
    {
      pageSize = 10,
      pageNumber = 1,
      sort = 'last-name',
      searchText = '',
      sortProp = 'name',
      sortOrder = 'ascending',
    } = {},
  ) => {
    const api = injector.get(injectNames.CONTACTS_API);

    const user = rootGetters['auth/CURRENT_USER'];
    const userId = user != null ? user.legacyId : null;

    const params = {
      pageSize,
      pageNumber,
      sort,
      searchText,
      sortProp,
      sortOrder,
      userId,
    };

    commit(mutations.SET_LOADING, true);
    api
      .getContacts(params)
      .then((resp) => {
        const { contacts } = resp;
        const { meta } = resp;
        commit(mutations.SET_TOTAL_ITEMS, meta.count);
        commit(mutations.SET_PAGE_NUMBER, pageNumber);
        commit(mutations.SET_TOTAL_PAGES, meta.pages);
        commit(mutations.SET_PAGE_SIZE, pageSize);
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, contacts);
      })
      .catch(() => {
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, []);
      });
  },
  [GET_ITEM_BY_ID]: async ({ commit, rootState }, { contactId, setAsCurrent = false }) => {
    const api = injector.get(injectNames.CONTACTS_API);
    commit(mutations.SET_LOADING, true);
    try {
      const item = await api.getContact(contactId);
      const { mainUser } = rootState.auth;
      if (item.type === types.CUSTOMER && mainUser) {
        try {
          const typeDivision = mainUser.customerTypeDivision;
          const field = ['ctd-highest-single-transaction', 'ctd-overall-revenue'][typeDivision - 1];
          const typeDivisions = await api.getCustomerTypeDivisions(mainUser.legacyId);
          const maxValue = typeDivisions.slice(-1)[0].attributes['value-to'];
          const value = Math.min(item.attributes[field], maxValue);

          item.customerTypeDivision = typeDivisions
            .find((c) => c.attributes['value-from'] <= value && value <= c.attributes['value-to']);
        } catch (e) {
          console.log(e);
        }
      }
      commit(mutations.APPEND_ITEM_TO_DATA, item);
      if (setAsCurrent) {
        commit(mutations.SET_CURRENT_ITEM, item);
      }
    } finally {
      commit(mutations.SET_LOADING, false);
    }
  },
  [UPDATE_ITEM]: async (
    { dispatch, commit },
    {
      item, setAsCurrent = false, tagsToAttach = [], tagsToDetach = [],
    },
  ) => {
    const api = injector.get(injectNames.CONTACTS_API);
    const $log = injector.get('$log');
    const vueInstance = injector.get(injectNames.VUE_INSTANCE);

    const updates = [api.updateContact(item)];

    if (tagsToAttach.length > 0) {
      updates.push(api.attachTags([item.id], tagsToAttach));
    }

    if (tagsToDetach.length > 0) {
      updates.push(api.detachTags([item.id], tagsToDetach));
    }

    try {
      commit(mutations.SET_UPDATING, true);
      // call all updates
      await Promise.all(updates);
      await dispatch(GET_ITEM_BY_ID, { contactId: item.id, setAsCurrent });
      const message = vueInstance.$t('notifications.contact-update-success');
      Vue.notify({
        data: {
          type: 'success',
          content: message,
        },
      });
    } catch (err) {
      const message = vueInstance.$t('notifications.contact-update-error');
      Vue.notify({
        data: {
          type: 'error',
          content: message,
        },
      });
      $log(err);
      throw err;
    } finally {
      commit(mutations.SET_UPDATING, false);
    }
  },
  [SAVE_FIELDS]: ({ commit }, fields) => {
    commit(mutations.SET_FIELDS, fields);
  },
  // eslint-disable-next-line
  [CREATE_EXPORT]: async ({ commit }, { format = 'xlsx', fields, tags = false, filters = {} }) => {
    const api = injector.get(injectNames.CONTACTS_API);
    const $log = injector.get('$log');
    try {
      const resp = await api.createExport({
        format,
        fields,
        filters,
        tags,
      });
      const { id, status } = resp;
      const item = { id, status };
      commit(mutations.SET_EXPORT_ITEM, { id, item });
      return resp;
    } catch (err) {
      $log(err);
    }
  },

  [CHECK_EXPORT]: async ({ commit }, exportId) => {
    const api = injector.get(injectNames.CONTACTS_API);
    const $log = injector.get('$log');
    try {
      const resp = await api.checkExport(exportId);
      const { id, status } = resp;
      const item = { id, status };
      commit(mutations.SET_EXPORT_ITEM, { id, item });
      return resp;
    } catch (err) {
      $log(err);
    }
    return undefined;
  },

  [LOAD_WIDGETS]: async ({ commit }) => {
    const api = injector.get(injectNames.CONTACTS_API);
    const [widgets, userWidgets] = await Promise.all([api.getAllWidgets(), api.getUserWidgets()]);
    commit(mutations.SET_WIDGETS, widgets);
    commit(mutations.SET_USER_WIDGETS, userWidgets);
  },

  [LOAD_USER_WIDGETS]: async ({ commit }) => {
    const userWidgets = await injector.get(injectNames.CONTACTS_API).getUserWidgets();
    commit(mutations.SET_USER_WIDGETS, userWidgets);
  },

  [CHANGE_WIDGETS]: async ({ state, /* commit, */ dispatch }, { attach, detach, order }) => {
    // console.log({ attach, detach, order });
    const api = injector.get(injectNames.CONTACTS_API);
    if (detach || attach) {
      const promises = [];
      if (detach) detach.map((id) => promises.push(api.detachUserWidget(id)));
      if (attach) {
        const maxOrder = max(state.userWidgets.filter((w) => order.includes(w['widget-id'])).map((w) => w.sorting))
          || 0;
        attach.map((id, index) => promises.push(api.attachUserWidget(id, maxOrder + index + 1)));
      }

      // вернуть после починки attach
      // const resultApi = await Promise.all(promises);

      // if (detach) {
      //   detach.map((id) => {
      //     if (resultApi.shift()) commit(mutations.DELETE_USER_WIDGETS_BY_WIDGET_ID, id);
      //   });
      // }

      // временно, пока attach не будет возвращать созданный объект,
      // запрашиваем все виджеты пользователя.
      // После просто добавить объект в state.userWidgets
      await Promise.all(promises);
      await dispatch(LOAD_USER_WIDGETS);
    } else if (order) {
      const newUserOrders = order.map((id) => state.userWidgets.find((w) => w['widget-id'] === id).id);
      if (await api.reorderUserWidgets(newUserOrders)) await dispatch(LOAD_USER_WIDGETS);
    }
  },
};

export default actions;
