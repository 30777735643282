export const DASHBOARD = 'dashboard';
export const ACTIVITY = 'activity';
export const FUNNELS = 'funnels';
export const OVERVIEW = 'overview';
export const PURCHASES = 'purchases';
export const TIMELINE = 'timeline';
export const WEBINARS = 'webinars';
export const MEMBERSHIP = 'membership';
export const NEW_IMPORT = 'new-import';
export const IMPORT_MAPPING = 'import-mapping';
