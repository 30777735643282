import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';
import { updateAbilityForEntity } from '@dmant/ez-lib/src/ability/update';
import $ability from '@dmant/ez-lib/src/ability/instance';
import PageNotFound from '../pages/404.vue';

// route names constants
import * as names from './names';
import * as authActions from '../store/auth/actions';
import * as authGetters from '../store/auth/getters';

Vue.use(Router);

const routerInstance = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/',
  routes: [
    {
      path: '/',
      name: 'checkAccess',
      component: () => import('@dmant/ez-lib/src/ability/checkAccess.vue'),
      props: {
        subject: 'Contacts',
        forbiddenComponent: () => import(/* webpackChunkName: "403" */ '../pages/403.vue'),
      },
      children: [
        {
          path: '',
          name: names.DASHBOARD,
          component: () => import(/* webpackChunkName: "dashboard" */ '../pages/Dashboard/Dashboard.vue'),
        },
        {
          path: '/new-import',
          name: names.NEW_IMPORT,
          component: () => import(/* webpackChunkName: "new-import" */ '../pages/Dashboard/NewImport.vue'),
          async beforeEnter(to, from, next) {
            return $ability.cannot('create', 'ContactsBulk')
              ? next({ name: names.DASHBOARD })
              : next();
          },
        },
        {
          path: '/import/:importId',
          name: names.IMPORT_MAPPING,
          component: () => import(/* */ '../pages/Dashboard/ImportMapping.vue'),
        },
        {
          path: '/contact/:contactId',
          component: () => import(/* webpackChunkName: "contactLayout" */ '../pages/ContactLayout.vue'),
          meta: { isContactPage: true },
          async beforeEnter(to, from, next) {
            to.meta.permissions = await updateAbilityForEntity('contacts', 'contact', to.params.contactId);
            if (!to.meta.permissions.update) {
              to.meta.accessDenied = true;
            }
            next();
          },
          children: [
            {
              path: '',
              name: names.OVERVIEW,
              component: () => import(/* webpackChunkName: "overview" */ '../pages/Overview/Overview.vue'),
              meta: { isContactPage: true },
            },
            {
              path: 'activity',
              name: names.ACTIVITY,
              component: () => import(/* webpackChunkName: "activity" */ '../pages/Activity.vue'),
              meta: { isContactPage: true },
            },
            {
              path: 'funnels',
              name: names.FUNNELS,
              component: () => import(/* webpackChunkName: "funnels" */ '../pages/Funnels.vue'),
              meta: { isContactPage: true },
            },
            {
              path: 'purchases',
              name: names.PURCHASES,
              component: () => import(/* webpackChunkName: "purchases" */ '../pages/Purchases.vue'),
              meta: { isContactPage: true },
            },
            {
              path: 'timeline',
              name: names.TIMELINE,
              component: () => import(/* webpackChunkName: "timeline" */ '../pages/Timeline/Timeline.vue'),
              meta: { isContactPage: true },
            },
            {
              path: 'webinars',
              name: names.WEBINARS,
              component: () => import(/* webpackChunkName: "webinars" */ '../pages/Webinars.vue'),
              meta: { isContactPage: true },
            },
            {
              path: 'membership',
              name: names.MEMBERSHIP,
              component: () => import(/* webpackChunkName: "webinars" */ '../pages/Membership.vue'),
              meta: { isContactPage: true },
            },
          ],
        },
      ],
    },
    {
      path: '*',
      name: 'page-not-found-404',
      component: PageNotFound,
      meta: { hideLiveChat: true },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

routerInstance.beforeEach(async (to, from, next) => {
  store.dispatch(`auth/${authActions.SET_IS_LOADING}`, true);
  const isLoggedIn = store.getters[`auth/${authGetters.IS_LOGGED_IN}`];
  if (isLoggedIn) {
    next();
  } else {
    await store.dispatch(`auth/${authActions.VALIDATE_SESSION}`);
    next();
  }
});
routerInstance.afterEach(() => store.dispatch(`auth/${authActions.SET_IS_LOADING}`, false));

routerInstance.afterEach((to) => {
  if (to.matched.some((record) => record.meta?.hideLiveChat)) {
    Vue.prototype.$LiveChat.hide();
  } else {
    Vue.prototype.$LiveChat.show();
  }
});

export default routerInstance;
