import contactType from './contactType';
import userTime from './userTime';
import percent from './percent';
import currency from './currency';

export default {
  'contact-type': contactType,
  'user-time': userTime,
  percent,
  currency,
};
