import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import helper from './helpers/Contacts';

const widgetFelds = ['name', 'type', 'value'];
const addUserWidgetFields = ['widget-id', 'sorting'];
const validateStatus = (status) => status === 200;

function transformWidgets2flatFormat(widget) {
  const attributes = get(widget, 'attributes', {});
  return {
    id: widget.id, ...pick(attributes, widgetFelds),
  };
}

function transformWidgets2UserflatFormat(widget) {
  const attributes = get(widget, 'attributes', {});
  return {
    id: widget.id, ...pick(attributes, [...widgetFelds, ...addUserWidgetFields]),
  };
}

class ContactsRepository {
  constructor({
    axios: axiosInstance, $log,
  }) {
    this.axios = axiosInstance || axios;
    this.$log = $log;
  }

  async getContact(id) {
    const url = `${URL_API_CONTACTS}/v1/contacts/${id}`;
    const params = {
      include: 'tags,customer-type-division',
    };
    const response = await this.axios.get(url, {
      params, withCredentials: true, validateStatus,
    });
    return helper.fromJsonApi(response.data.data, response.data.included || []);
  }

  async getContacts(params) {
    const {
      pageSize = 10, pageNumber = 1, searchText = '', sortProp = 'name', sortOrder = 'ascending',
      userId,
    } = params || {};

    const url = `${URL_API_CONTACTS}/v1/contacts`;

    const queryParams = {
      include: 'tags',
      'page[size]': pageSize,
      'page[number]': pageNumber,
      'filter[contacts][and][user][eq]': userId,
    };

    const cleanedSearchText = searchText.replace(/[^a-zA-Z0-9äöüßÄÖÜẞа-яА-Я]/g, '');

    if (searchText !== '') {
      queryParams['filter[contacts][or][first-name][like]'] = searchText;
      queryParams['filter[contacts][or][last-name][like]'] = searchText;
      queryParams['filter[contacts][or][email][like]'] = searchText;
      queryParams['filter[contacts][or][phone][like]'] = cleanedSearchText;
      queryParams['filter[contacts][or][country][like]'] = searchText;
      queryParams['filter[contacts][or][dtCreate][like]'] = searchText;
    }

    // init sort
    let sort = sortOrder === 'ascending' ? '-optinDate' : 'optinDate';
    switch (sortProp) {
      case 'city':
        sort = sortOrder === 'ascending' ? 'city' : '-city';
        break;
      case 'state':
        sort = sortOrder === 'ascending' ? 'state' : '-state';
        break;
      case 'zip':
        sort = sortOrder === 'ascending' ? '=zip' : '-zip';
        break;
      case 'country':
        sort = sortOrder === 'ascending' ? 'country' : '-country';
        break;
      case 'phone':
        sort = sortOrder === 'ascending' ? 'phone' : '-phone';
        break;
      case 'page-visits':
        sort = sortOrder === 'ascending' ? 'page-visits' : '-page-visits';
        break;
      case 'revenue':
        sort = sortOrder === 'ascending' ? 'revenue' : '-revenue';
        break;
      case 'optinDate':
        sort = sortOrder === 'ascending' ? '-optinDate' : 'optinDate';
        break;
      default:
        break;
    }

    queryParams.sort = sort;

    const response = await this.axios.get(url, {
      withCredentials: true, params: queryParams, validateStatus,
    });

    const contacts = response.data.data.map((item) => helper.fromJsonApi(item));
    const { meta } = response.data;
    return { contacts, meta };
  }

  async updateContact(item) {
    const url = `${URL_API_CONTACTS}/v1/contacts/${item.id}`;

    const data = helper.toJsonApi(item);
    const response = await this.axios.patch(url, data, {
      withCredentials: true, validateStatus,
    });

    return response.data.data;
  }

  async createContact(item) {
    const url = `${URL_API_CONTACTS}/v1/contacts`;

    const data = helper.toJsonApi(item);
    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });

    return response.data.data;
  }

  async deleteContacts(contactsIds = []) {
    const url = `${URL_API_CONTACTS}/v1/bulk/delete-contacts`;

    const data = {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((item) => ({
              type: 'contacts', id: item,
            })),
          },
        },
        type: 'bulk-delete-contacts',
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });

    return response.data.data;
  }

  async subscribeContacts(contactsIds = []) {
    const url = `${URL_API_CONTACTS}/v1/bulk/subscribe-contacts`;

    const data = {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((item) => ({
              type: 'contacts', id: item,
            })),
          },
        },
        type: 'bulk-subscribe-contacts',
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });

    return response.data.data;
  }

  async unsubscribeContacts(contactsIds = []) {
    const url = `${URL_API_CONTACTS}/v1/bulk/unsubscribe-contacts`;

    const data = {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((item) => ({
              type: 'contacts', id: item,
            })),
          },
        },
        type: 'bulk-unsubscribe-contacts',
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return response.data.data;
  }

  async attachTags(contactsIds = [], tagsIds = []) {
    const url = `${URL_API_CONTACTS}/v1/bulk/attach-tags`;

    const data = {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((item) => ({
              type: 'contacts', id: item,
            })),
          },
          tags: {
            data: tagsIds.map((item) => ({
              type: 'tags', id: item,
            })),
          },
        },
        type: 'bulk-attach-tags',
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return response.data.data;
  }

  async detachTags(contactsIds = [], tagsIds = []) {
    const url = `${URL_API_CONTACTS}/v1/bulk/detach-tags`;

    const data = {
      data: {
        relationships: {
          contacts: {
            data: contactsIds.map((item) => ({
              type: 'contacts', id: item,
            })),
          },
          tags: {
            data: tagsIds.map((item) => ({
              type: 'tags', id: item,
            })),
          },
        },
        type: 'bulk-detach-tags',
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return response.data.data;
  }

  // Export

  async createExport({
    filters = {}, format = 'xlsx', tags = false,
    fields = ['contact_id', 'email', 'first_name', 'last_name'],
  }) {
    const url = `${URL_API_CONTACTS}/v1/contacts/export`;

    const data = {
      data: {
        format, filters, fields, tags,
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });

    return response.data.data;
  }

  async checkExport(exportId) {
    const url = `${URL_API_CONTACTS}/v1/contacts/export/${exportId}`;
    const response = await this.axios.get(url, {
      withCredentials: true, validateStatus,
    });
    return response.data.data;
  }

  // Import

  async createImport(file, skipHeader) {
    const url = `${URL_API_CONTACTS}/v1/contacts/import`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', 'xlsx');
    formData.append('skip_header', skipHeader ? '1' : '0');
    const response = await this.axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      validateStatus,
    });
    return response.data.data;
  }

  async checkImport(importId) {
    const url = `${URL_API_CONTACTS}/v1/contacts/import/${importId}`;
    const response = await this.axios.get(url, {
      withCredentials: true, validateStatus,
    });
    return response.data.data;
  }

  async processImport(importId, mapping, tags = []) {
    const url = `${URL_API_CONTACTS}/v1/contacts/import-mapping`;

    const data = {
      data: {
        'import-id': importId,
        mapping,
        tags: {
          data: tags.map((id) => ({
            type: 'tags', id,
          })),
        },
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return response.data;
  }

  // Widgets

  async getAllWidgets() {
    const url = `${URL_API_CONTACTS}/v1/widgets`;

    const response = await this.axios.get(url, {
      withCredentials: true, validateStatus,
    });
    return response.data.data.map((widget) => transformWidgets2flatFormat(widget));
  }

  async getUserWidgets() {
    const url = `${URL_API_CONTACTS}/v1/user-widgets`;
    const response = await this.axios.get(url, {
      withCredentials: true, validateStatus,
    });
    return response.data.data.map((widget) => transformWidgets2UserflatFormat(widget));
  }

  async attachUserWidget(widgetId, order) {
    const url = `${URL_API_CONTACTS}/v1/user-widgets/attach`;

    const data = {
      data: {
        type: 'user-widgets-attach',
        attributes: {
          sorting: order,
        },
        relationships: {
          widgets: {
            data: {
              type: 'widgets', id: widgetId,
            },
          },
        },
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return response.data.data;
  }

  async detachUserWidget(widgetId) {
    const url = `${URL_API_CONTACTS}/v1/user-widgets/detach`;

    const data = {
      data: {
        type: 'user-widgets-detach',
        relationships: {
          widgets: {
            data: {
              type: 'widgets', id: widgetId,
            },
          },
        },
      },
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return !!response.data;
  }

  async reorderUserWidgets(widgetIds) {
    const url = `${URL_API_CONTACTS}/v1/user-widgets/reorder`;

    const data = {
      data: widgetIds.map((item, index) => ({
        id: item,
        type: 'user-widgets',
        attributes: {
          sorting: index + 1,
        },
      })),
    };

    const response = await this.axios.post(url, data, {
      withCredentials: true, validateStatus,
    });
    return !!response.data;
  }

  async getCustomerTypeDivisions(userId) {
    const url = `${URL_API_CONTACTS}/v1/customer-type-divisions`;
    const params = {
      'filter[user][eq]': userId, sort: 'value-from',
    };

    const response = await this.axios.get(url, {
      params, withCredentials: true, validateStatus,
    });
    return response.data.data || [];
  }
}

export default ContactsRepository;
