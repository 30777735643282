export const ROOT_BREADCRUMB = {
  name: 'Contacts',
  status: '',
  href: '/',
};

const state = {
  breadcrumbs: [],
};

export default state;
