/* eslint-disable class-methods-use-this */
import { URL_API_ACADEMY } from '@dmant/ez-env-common';
import axios from 'axios';
import processResponse from './helpers/processResponse';

class AcademiesRepository {
  constructor({
    axios: axiosInstance, $log,
  }) {
    this.axios = axiosInstance || axios;
    this.$log = $log;
  }

  async getMemberCourses(memberIds = []) {
    const url = `${URL_API_ACADEMY}/v1/members-courses`;

    const queryParams = {
      include: 'courses',
      'filter[members-courses][and][members][in]': memberIds.join(','),
    };

    let result = null;
    let response = null;

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = processResponse(response.data);
    }

    return result;
  }

  async getMembers(contactId) {
    const url = `${URL_API_ACADEMY}/v1/members`;

    let result = null;
    let response = null;

    const queryParams = {
      include: 'academies,members-courses,courses,packages',
      'filter[members][and][contact-id][eq]': contactId,
    };

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = processResponse(response.data);
    }

    return result;
  }

  async getAcademies(userId) {
    const url = `${URL_API_ACADEMY}/v1/academies`;

    let result = null;
    let response = null;

    const queryParams = {
      'filter[academies][and][user][eq]': userId,
      include: 'courses,packages',
      'filter[academies][and][deleted-at][isNull]': 'deleted-at',
    };

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = processResponse(response.data);
    }

    return result;
  }

  async getCourses(academies = []) {
    return `getCourses result for academies ${academies}`;
  }

  async getPackages(academies = []) {
    return `getPackeges result for packages ${academies}`;
  }

  async getMemberPackages(memberIds = []) {
    const url = `${URL_API_ACADEMY}/v1/members-courses`;

    let result = null;
    let response = null;

    const queryParams = {
      include: 'courses',
      'filter[members-courses][and][members][in]': memberIds.join(','),
    };

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = processResponse(response.data);
    }

    return result;
  }

  async addCoursesToMember(academyId, courses, memberId) {
    return `addCourseToMember result for member ${memberId} and course ${courses} and academy ${academyId}`;
  }

  async createOrder(
    academyId,
    courses,
    packages,
    contactId,
    isNotification = false,
  ) {
    const url = `${URL_API_ACADEMY}/v1/orders`;

    let result = null;
    let response = null;

    const payload = {
      data: {
        attributes: {
          'contact-id': contactId,
          'is-active': true,
          'is-notification': isNotification,
        },
        relationships: {
          academies: {
            data: {
              type: 'academies',
              id: academyId,
            },
          },
          courses: {
            data: courses.map((id) => ({
              type: 'courses',
              id,
            })),
          },
          packages: {
            data: packages.map((id) => ({
              type: 'packages',
              id,
            })),
          },
        },
        type: 'orders',
      },
    };

    try {
      response = await this.axios.post(url, payload, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = response.data;
    }

    return result;
  }

  async createMember(academyId, courses, contact, isNotification = false) {
    const url = `${URL_API_ACADEMY}/v1/members`;

    let result = null;
    let response = null;

    const payload = {
      data: {
        attributes: {
          ...contact,
          'is-notification': isNotification,
        },
        relationships: {
          academies: {
            data: {
              type: 'academies',
              id: academyId,
            },
          },
          courses: {
            data: courses.map((id) => ({
              type: 'courses',
              id,
            })),
          },
        },
        type: 'members',
      },
    };

    try {
      response = await this.axios.post(url, payload, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = processResponse(response.data);
    }

    return result;
  }

  async addPackageToMember(packageId, memberId) {
    return `addPackageToMember result for member ${memberId} and package ${packageId}`;
  }

  async deleteMember(memberId) {
    const url = `${URL_API_ACADEMY}/v1/members/${memberId}`;

    let result = null;
    let response = null;

    try {
      response = await this.axios.delete(url, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = response.data;
    }

    return result;
  }

  async deleteMemberCourse(memberCourseId) {
    const url = `${URL_API_ACADEMY}/v1/members-courses/${memberCourseId}`;

    let result = null;
    let response = null;

    try {
      response = await this.axios.delete(url, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = response.data;
    }

    return result;
  }

  async updateMemberCourseIsActive(memberCourseId, isActive) {
    const url = `${URL_API_ACADEMY}/v1/members-courses/${memberCourseId}`;

    let result = null;
    let response = null;

    try {
      const data = {
        data: {
          attributes: {
            'is-active': isActive ? 1 : 0,
          },
          type: 'members-courses',
        },
      };
      response = await this.axios.patch(url, data, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = response.data;
    }

    return result;
  }

  async updateMemberPassword(memberId, password, notification = false) {
    const url = `${URL_API_ACADEMY}/v1/members/${memberId}/change-password`;

    let result = null;
    let response = null;

    try {
      const data = {
        data: {
          password,
          notification,
        },
      };
      response = await this.axios.patch(url, data, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = response.data;
    }

    return result;
  }
}

export default AcademiesRepository;
