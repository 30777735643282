import activity from './activity';
import dashboard from './dashboard';
import funnels from './funnels';
import membership from './membership';
import purchases from './purchases';
import timeline from './timeline';
import webinars from './webinars';

const contacts = {
  namespaced: true,
  modules: {
    activity,
    dashboard,
    funnels,
    membership,
    purchases,
    timeline,
    webinars,
  },
};

export default contacts;
