export const DATA = 'DATA';
export const ITEMS = 'ITEMS';
export const CURRENT_ITEM = 'CURRENT_ITEM';
export const LOADING = 'LOADING';
export const TOTAL_ITEMS = 'TOTAL_ITEMS';
export const TOTAL_PAGES = 'TOTAL_PAGES';
export const PAGE_NUMBER = 'PAGE_NUMBER';
export const PAGE_SIZE = 'PAGE_SIZE';

const getters = {
  [DATA]: (state) => state.data,
  [CURRENT_ITEM]: (state) => state.currentItem,
  [ITEMS]: (state) => (state.data && state.data.data ? state.data.data : []),
  [LOADING]: (state) => state.loading,
  [TOTAL_ITEMS]: (state) => state.totalItems,
  [TOTAL_PAGES]: (state) => state.totalPages,
  [PAGE_NUMBER]: (state) => state.pageNumber,
  [PAGE_SIZE]: (state) => state.pageSize,
};

export default getters;
