export const types = {
  LEAD: 1,
  CUSTOMER: 2,
};

export default (value) => {
  if (value == null) {
    return null;
  }
  switch (value) {
    case types.LEAD:
      return 'Lead';
    case types.CUSTOMER:
      return 'Customer';
    default:
      return null;
  }
};
