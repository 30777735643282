<template>
  <ez-info-box full-height slot="content">
    <div slot="image" class="error-image">
      <!-- eslint-disable -->
      <svg
        class="error-image__svg"
        viewBox="0 0 375 221"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M156.747 191C35.5777 193.555 48.537 193.555 28.882 195.258C9.01112 196.749 -30.9467 208.884 42.2733 213.568C115.277 218.252 177.698 220.381 221.544 220.168C265.389 219.956 236.015 217.401 293.9 218.891C351.785 220.381 376.839 225.704 374.895 210.375C372.951 195.045 338.393 189.935 271.869 193.981C205.344 198.026 156.747 191 156.747 191Z"
          fill="#F7DFDA"
        />
        <path
          d="M160.609 208.951C159.683 208.951 157.679 209.026 156.908 209.177C155.597 209.478 154.749 210.383 155.058 211.362C155.443 212.493 156.754 213.246 159.915 213.246C162.305 213.246 163.693 213.699 163.847 212.719C164.001 211.362 164.078 210.76 164.001 209.403C163.924 208.649 162.922 208.951 160.609 208.951Z"
          fill="#E0AFA5"
        />
        <path
          d="M176.877 208.876C174.872 208.951 172.79 208.951 170.786 208.876C164.926 208.725 166.391 209.252 166.083 211.438C166.083 211.664 165.851 213.171 166.006 213.397C166.237 213.925 169.475 214 174.332 214C176.491 214 178.727 213.849 180.809 213.774C183.045 213.699 185.435 213.246 185.897 211.739C186.9 208.649 180.732 208.725 176.877 208.876Z"
          fill="#E0AFA5"
        />
        <path
          d="M191.603 198.702C190.678 198.702 188.673 198.777 187.902 198.928C186.591 199.229 185.743 200.134 186.052 201.113C186.437 202.244 187.748 202.997 190.909 202.997C193.299 202.997 194.687 203.45 194.841 202.47C194.995 201.113 195.072 200.511 194.995 199.154C194.918 198.325 193.916 198.627 191.603 198.702Z"
          fill="#E0AFA5"
        />
        <path
          d="M207.871 198.627C205.866 198.702 203.785 198.702 201.78 198.627C195.92 198.476 197.385 199.003 197.077 201.189C197.077 201.415 196.846 202.922 197 203.148C197.231 203.676 200.469 203.751 205.326 203.751C207.485 203.751 209.721 203.6 211.803 203.525C214.039 203.45 216.429 202.997 216.891 201.49C217.894 198.325 211.726 198.476 207.871 198.627Z"
          fill="#E0AFA5"
        />
        <path
          d="M143.54 77.9132L131.475 65.5801L126.971 38.769C126.971 38.769 135.711 41.5037 136.569 42.4689C137.481 43.4341 147.186 60.8077 147.186 60.8077C147.186 60.8077 150.243 72.3901 150.243 72.8727C150.243 73.3017 143.54 77.9132 143.54 77.9132Z"
          fill="#6E4E46"
        />
        <path
          d="M120.643 189.716L121.019 195.668C121.019 195.668 122.091 197.437 123.324 197.866C124.558 198.295 127.185 196.311 127.561 195.507L127.346 188.858L120.643 189.716Z"
          fill="#FFBFA2"
        />
        <path
          d="M120.965 195.561C120.965 195.561 122.198 196.043 124.129 196.097C126.113 196.15 127.507 195.561 127.507 195.561C127.507 195.561 135.068 201.459 135.765 201.888C136.462 202.317 144.183 203.658 144.452 204.033C144.773 204.408 144.505 206.982 144.505 207.358C144.452 207.733 128.901 207.25 128.794 207.036C128.74 206.821 128.365 204.837 127.668 204.945C126.971 204.998 126.595 206.66 126.327 206.714C126.059 206.821 120.911 206.553 119.356 206.446C119.088 206.446 118.927 206.231 118.927 205.963C118.981 204.676 119.249 200.869 119.517 199.1C119.946 196.901 120.965 195.561 120.965 195.561Z"
          fill="#6E4E46"
        />
        <path
          d="M104.02 189.716L103.645 195.721C103.645 195.721 102.573 197.491 101.339 197.92C100.106 198.349 97.4784 196.365 97.103 195.561L97.3175 188.911L104.02 189.716Z"
          fill="#FFBFA2"
        />
        <path
          d="M103.645 195.561C103.645 195.561 102.412 196.043 100.481 196.097C98.4972 196.15 97.103 195.561 97.103 195.561C97.103 195.561 89.5423 201.459 88.8452 201.888C88.1481 202.317 80.4265 203.658 80.1584 204.033C79.8367 204.408 80.1048 206.982 80.1048 207.358C80.1584 207.733 95.7089 207.25 95.8161 207.036C95.8697 206.821 96.2451 204.837 96.9422 204.945C97.6393 204.998 98.0146 206.66 98.2827 206.714C98.5508 206.821 103.699 206.553 105.254 206.446C105.522 206.446 105.683 206.231 105.683 205.963C105.629 204.676 105.361 200.869 105.093 199.1C104.717 196.901 103.645 195.561 103.645 195.561Z"
          fill="#6E4E46"
        />
        <path
          d="M121.34 145.638C121.34 145.638 111.42 147.729 106.326 144.941C101.232 142.153 105.522 122.849 107.184 121.347C108.846 119.846 113.565 115.556 115.924 115.556C118.23 115.556 120.322 114.001 121.126 118.72C121.823 123.492 121.34 145.638 121.34 145.638Z"
          fill="#5C3E36"
        />
        <path
          d="M132.547 134.914C132.547 134.914 131.046 190.467 130.939 191.11C130.831 191.753 126.005 192.826 124.504 192.826C121.608 192.826 118.016 191.485 117.748 191.164C117.48 190.842 117.748 149.66 117.48 144.566C117.211 139.472 114.369 106.119 114.101 105.046C113.833 103.974 117.48 88.5841 117.48 88.5841C117.48 88.5841 126.005 89.1203 127.132 89.4421C128.311 89.7638 130.081 89.0667 130.188 90.0319C130.242 91.1044 132.547 134.914 132.547 134.914Z"
          fill="#8A7974"
        />
        <path
          d="M97.5856 134.914C97.5856 134.914 94.3147 190.467 94.4756 191.11C94.6364 191.753 99.4088 192.826 100.91 192.826C102.948 192.826 107.559 191.539 107.667 191.164C107.828 190.735 112.493 149.982 112.761 144.888C113.029 139.793 119.088 93.8391 119.356 92.7666C119.624 91.6942 111.742 88.5841 111.742 88.5841C111.742 88.5841 103.216 89.1203 102.09 89.4421C100.91 89.7638 99.1407 89.0667 99.0335 90.0319C98.9262 91.1044 97.5856 134.914 97.5856 134.914Z"
          fill="#8A7974"
        />
        <path
          d="M110.562 19.5186L108.954 35.498L118.713 42.4689L121.34 30.1358L110.562 19.5186Z"
          fill="#FFBFA2"
        />
        <path
          d="M120.161 35.6589C120.161 35.6589 117.211 36.5168 116.622 36.5705C116.032 36.6241 109.383 33.6212 109.168 33.514C108.954 33.4067 105.79 34.8545 105.79 34.8545C105.79 34.8545 105.575 77.1626 105.79 83.5436C106.004 89.9247 109.383 93.6782 115.871 94.4826C122.359 95.2869 127.721 91.158 128.901 89.4957C130.134 87.8334 126.649 40.8066 126.434 39.895C126.22 38.9835 120.161 35.6589 120.161 35.6589Z"
          fill="#FFE8DB"
        />
        <path
          d="M106.916 34.3719C106.916 34.3719 109.114 50.0833 109.061 57.3759C109.061 64.7222 108.149 146.657 106.916 146.925C105.683 147.193 100.642 143.761 98.1755 141.992C95.6552 140.222 88.1481 136.791 88.1481 135.879C88.1481 134.967 94.4755 91.5333 95.0118 85.903C95.9233 76.6799 97.8001 39.3052 98.1219 38.1791C98.4436 37.053 106.916 34.3719 106.916 34.3719Z"
          fill="#6E4E46"
        />
        <path
          d="M122.627 37.1067C122.627 37.1067 123.807 49.547 124.236 58.6628C124.665 67.7786 124.504 147.408 125.63 146.979C126.756 146.496 131.958 144.03 135.175 142.582C138.392 141.134 142.789 137.434 143.057 136.147C143.594 133.305 137.856 95.126 136.891 85.8494C134.96 67.1352 131.475 41.7718 130.188 40.4849C128.901 39.2516 122.627 37.1067 122.627 37.1067Z"
          fill="#6E4E46"
        />
        <path
          d="M117.372 40.4313H115.871C115.335 40.4313 114.906 40.0559 115.013 39.6269L115.549 37.482C115.603 37.1603 115.978 36.9458 116.407 36.9458H116.89C117.319 36.9458 117.694 37.2139 117.748 37.5356L118.23 39.6805C118.337 40.0559 117.908 40.4313 117.372 40.4313Z"
          fill="#EB6776"
        />
        <path
          d="M117.319 39.466C117.319 39.466 117.48 42.6834 117.587 44.2384C117.694 45.7935 119.624 67.1351 119.624 67.8322C119.624 68.5293 116.353 74.4278 115.871 74.4278C115.388 74.3742 112.064 68.8511 112.064 68.2076C112.064 67.5641 115.067 46.6514 115.335 44.4529C115.656 41.8254 115.978 38.9834 115.978 38.9834L117.319 39.466Z"
          fill="#EB6776"
        />
        <path
          d="M99.6769 37.1067C99.6769 37.1067 92.5988 39.895 92.2234 40.6457C91.3654 42.6834 88.0409 54.5339 86.0568 65.9018C84.2873 76.1437 82.3569 87.9406 83.3757 88.9058C84.2337 89.7102 90.5611 90.1928 91.7944 89.3884C93.8857 87.9942 98.6045 83.061 98.6045 83.061L100.535 39.0371L99.6769 37.1067Z"
          fill="#6E4E46"
        />
        <path
          d="M135.282 36.6241C135.282 36.6241 134.96 31.8517 134.639 31.3691C134.317 30.8865 132.923 30.9401 132.494 31.1546C131.689 31.5836 132.011 35.5516 132.011 35.8197C132.011 35.9806 135.282 36.6241 135.282 36.6241Z"
          fill="#F2A57E"
        />
        <path
          d="M125.523 38.3936L126.22 39.0907C126.488 39.3588 126.863 39.3052 127.132 39.0907L136.301 29.5995C136.569 29.3314 136.515 28.9561 136.301 28.6879L135.604 27.9909C135.336 27.7227 134.96 27.7764 134.692 27.9909L125.523 37.4284C125.255 37.7501 125.255 38.1255 125.523 38.3936Z"
          fill="#2E1E15"
        />
        <path
          d="M125.148 39.8414L126.703 39.1443L125.469 37.911L124.772 39.466C124.611 39.7341 124.879 40.0022 125.148 39.8414Z"
          fill="#2E1E15"
        />
        <path
          d="M138.928 47.6166C138.928 47.6166 137.588 44.1848 137.427 43.9167C137.266 43.6486 135.711 36.0342 135.497 35.498C135.282 34.9618 132.816 32.6024 132.065 32.1198C131.314 31.5836 130.403 31.4227 129.759 31.5299C129.116 31.5836 128.418 32.656 128.526 33.0314C128.633 33.4067 130.134 34.3719 130.617 34.8009C131.046 35.2299 131.421 36.1951 131.26 36.3023C131.1 36.4096 129.813 36.6777 128.794 37.3748C127.775 38.0719 128.097 38.9298 127.453 39.7878C126.81 40.6457 126.166 41.2356 126.327 42.6298C126.488 44.0239 128.097 47.563 129.974 48.3673C131.85 49.1717 133.084 48.6891 133.566 49.118C134.049 49.547 135.229 49.6007 135.229 49.6007L138.928 47.6166Z"
          fill="#FFBFA2"
        />
        <path
          d="M133.191 51.9064C133.191 51.9064 132.011 49.8687 132.065 49.4398C132.118 49.0108 133.513 47.6166 136.033 46.4369C136.837 46.0616 139.357 45.4181 139.679 45.6326C140.055 45.7935 141.181 47.992 141.181 47.992L133.191 51.9064Z"
          fill="#FFE8DB"
        />
        <path
          d="M141.824 47.4021C141.502 47.2413 138.875 47.0804 136.086 48.8499C133.62 50.405 131.475 52.8716 131.743 53.247C131.958 53.676 142.789 77.1089 143.808 78.2886C144.827 79.4683 150.833 82.632 152.12 81.8813C153.46 81.1306 154.157 74.535 153.46 72.3901C152.763 70.2453 143.379 48.1529 141.824 47.4021Z"
          fill="#6E4E46"
        />
        <path
          d="M111.313 60.2179C111.688 61.612 122.895 63.9714 124.129 64.0787C125.416 64.1859 129.062 45.2036 129.223 43.7022C129.384 42.2008 114.906 39.6269 114.584 40.2167C114.262 40.8066 110.938 58.8237 111.313 60.2179Z"
          fill="#8A7974"
        />
        <path
          d="M104.181 62.041L106.594 58.4483C106.594 58.4483 110.562 54.1586 111.152 53.7832C112.332 53.0861 115.817 50.7803 116.3 50.9948C116.782 51.1557 117.748 52.2818 117.426 52.6571C117.104 53.0325 113.082 55.8745 112.868 56.3034C112.707 56.7324 115.388 56.1426 116.89 55.6063C118.391 55.0701 121.501 54.7484 121.662 55.0165C121.823 55.2846 121.984 56.9469 121.662 57.1614C121.34 57.3759 120.858 57.5368 120.965 57.8049C121.072 58.073 121.394 59.5208 121.072 59.8961C120.75 60.2715 119.249 60.8077 119.249 61.0222C119.249 61.2367 119.785 62.5773 119.088 63.0062C118.391 63.4352 112.225 65.6337 110.133 65.9018C108.042 66.2236 104.181 62.041 104.181 62.041Z"
          fill="#FFBFA2"
        />
        <path
          d="M101.715 63.2744C101.715 63.2744 103.27 61.5584 103.699 61.4512C104.128 61.344 105.897 62.2555 107.774 64.2932C108.364 64.9367 109.758 67.1352 109.704 67.5105C109.651 67.9395 107.935 69.709 107.935 69.709L101.715 63.2744Z"
          fill="#FFE8DB"
        />
        <path
          d="M101.607 61.5584C101.607 61.5584 110.401 70.0844 110.723 70.7815C111.045 71.4786 101.071 82.0422 101.071 82.0422L101.607 61.5584Z"
          fill="#6E4E46"
        />
        <path
          d="M107.47 4.28979C107.47 4.28979 106.428 4.92065 106.095 5.76178C105.762 6.60292 106.428 12.4909 106.762 14.6357C107.095 16.7806 109.053 23.5938 109.053 23.5938L113.511 19.304L111.762 7.73845L107.47 4.28979Z"
          fill="#A25E4D"
        />
        <path
          d="M125.845 24.6264V26.0502C125.845 28.2697 124.062 30.0285 121.906 30.0285H114.443C111.872 30.0285 109.758 27.9347 109.758 25.2965V8.04333H125.72L125.762 12.6916V13.6129L125.803 22.9095L125.845 24.6264Z"
          fill="#FFBFA2"
        />
        <path
          d="M111.098 20.3765C112.431 20.3765 113.511 18.936 113.511 17.1591C113.511 15.3823 112.431 13.9418 111.098 13.9418C109.766 13.9418 108.685 15.3823 108.685 17.1591C108.685 18.936 109.766 20.3765 111.098 20.3765Z"
          fill="#FFBFA2"
        />
        <path
          d="M113.242 9.71329C113.242 9.71329 114.056 14.011 112.855 14.3759C111.653 14.7813 110.219 13.8488 110.219 13.8488C110.219 13.8488 108.708 9.55112 109.406 7.40226C110.103 5.25341 111.304 5.86158 112.351 6.46974C113.358 7.07791 113.242 9.71329 113.242 9.71329Z"
          fill="#A25E4D"
        />
        <path
          d="M110.933 4.11573C110.933 4.11573 110.89 7.7261 111.961 8.69166C113.031 9.65723 113.031 9.65722 113.031 9.65722C113.031 9.65722 117.954 9.99308 121.08 10.161C124.205 10.3289 128.058 9.69921 128.358 8.85959C128.657 8.01997 128.657 3.10819 127.501 1.84876C126.345 0.589333 119.367 0.799238 117.184 0.379428C115 -0.0403816 109.649 -0.376231 108.279 0.883199C106.952 2.14263 107.08 4.53554 107.08 4.53554L108.921 6.8445L112.004 6.29875L110.933 4.11573Z"
          fill="#A25E4D"
        />
        <path
          d="M241.577 192.912C241.577 192.912 260.506 192.215 261.31 191.142C262.114 190.07 262.651 182.295 263.187 181.758C263.723 181.222 264.259 190.552 264.688 191.41C265.117 192.268 278.898 192.215 278.898 192.215L285.869 162.723C285.869 162.723 278.255 138.7 277.343 137.359C276.432 136.019 240.558 113.873 240.558 113.873C240.558 113.873 240.29 153.07 240.129 155.001C239.968 156.931 241.577 192.912 241.577 192.912Z"
          fill="#5C3E36"
        />
        <path
          d="M235.518 142.024C235.518 142.024 234.445 194.521 234.284 195.057C234.123 195.593 243.239 197.47 244.58 197.738C245.92 198.006 246.028 197.202 245.974 184.332C245.92 171.463 243.132 141.005 243.132 141.005L240.129 137.359L235.518 142.024Z"
          fill="#6E4E46"
        />
        <path
          d="M316.863 184.011L322.118 183.099C322.118 183.099 323.887 184.171 324.316 185.458C324.745 186.745 324.263 188.837 323.458 189.212C322.654 189.587 317.453 190.552 317.453 190.552L316.863 184.011Z"
          fill="#FFBFA2"
        />
        <path
          d="M321.206 183.26C321.206 183.26 322.386 184.332 322.439 186.316C322.493 188.3 321.85 189.695 321.85 189.695C321.85 189.695 327.694 197.309 328.123 198.006C328.552 198.703 332.628 199.561 333.003 199.507C333.378 199.507 333.539 191.196 333.325 191.142C333.11 191.089 331.18 190.713 331.233 189.963C331.341 189.266 332.949 188.944 333.057 188.622C333.164 188.354 332.949 183.206 332.896 181.651C332.896 181.383 332.681 181.222 332.413 181.222C331.126 181.276 327.319 181.49 325.496 181.758C323.297 182.08 321.206 183.26 321.206 183.26Z"
          fill="#6E4E46"
        />
        <path
          d="M326.836 194.789C326.836 194.789 326.729 200.419 326.836 200.741C326.944 201.009 321.742 203.368 321.206 203.851C320.67 204.333 320.509 205.728 320.938 206.103C321.367 206.478 331.877 206.157 332.413 205.674C332.949 205.191 333.271 197.899 333.271 197.899C333.271 197.899 330.965 194.306 330.751 194.306C330.536 194.306 326.836 194.789 326.836 194.789Z"
          fill="#6E4E46"
        />
        <path
          d="M256.269 189.963L260.291 194.574C260.291 194.574 260.506 195.539 259.755 196.665C259.004 197.792 257.074 198.703 256.269 198.381C255.465 198.006 251.497 194.467 251.497 194.467L256.269 189.963Z"
          fill="#FFBFA2"
        />
        <path
          d="M257.074 148.673C256.538 148.673 221.415 157.253 218.895 158.218C216.374 159.183 213.961 162.508 214.337 163.849C214.712 165.189 217.822 169.747 220.074 171.624C222.327 173.501 247.154 195.807 247.69 195.807C248.226 195.807 251.765 195.325 254.768 192.215C257.181 189.748 258.843 187.979 258.843 187.442C258.79 186.906 236.429 168.675 235.464 168.138C234.499 167.602 267.637 167.656 267.637 167.656C267.637 167.656 268.066 153.768 267.637 153.285C267.208 152.856 260.452 148.03 259.809 147.708C259.219 147.333 257.074 148.673 257.074 148.673Z"
          fill="#8A7974"
        />
        <path
          d="M292.089 172.696L292.196 187.871L288.711 189.051L282.437 175.109C282.437 175.109 283.402 169.372 283.67 169.372C283.939 169.372 292.089 172.696 292.089 172.696Z"
          fill="#6E4E46"
        />
        <path
          d="M261.364 149.8C261.364 150.389 279.81 202.028 281.847 202.296C283.885 202.564 303.028 198.113 307.854 197.47C312.68 196.826 318.739 195.593 319.008 194.95C319.329 194.145 318.954 190.016 318.471 187.818C317.399 182.885 316.648 182.616 315.951 181.973C315.254 181.329 292.733 187.603 290.588 187.442C288.443 187.282 285.226 167.87 284.046 159.988C282.866 152.105 278.255 143.687 278.255 143.687L261.364 149.8Z"
          fill="#8A7974"
        />
        <path
          d="M251.765 91.1367L258.843 103.255L256.323 109.958L251.283 111.299L244.58 103.255L251.765 91.1367Z"
          fill="#FFBFA2"
        />
        <path
          d="M246.081 105.079L249.459 106.955C250.157 107.277 250.961 107.116 251.497 106.58L257.181 100.413C257.181 100.413 259.058 100.95 259.862 101.218C260.667 101.486 264.045 119.825 265.278 121.648C266.511 123.417 273.482 131.193 273.75 131.675C274.019 132.104 277.021 145.027 277.129 145.402C277.236 145.778 261.685 149.692 261.364 149.853C261.042 150.014 253.588 149.639 253.588 149.639C253.588 149.639 251.551 145.51 250.264 142.668C249.191 140.255 246.081 132.587 244.58 127.546C243.776 124.812 242.006 108.51 242.435 107.438C242.864 106.365 246.081 105.079 246.081 105.079Z"
          fill="#FFE8DB"
        />
        <path
          d="M251.23 110.762L252.678 110.387C253.214 110.226 253.536 109.744 253.321 109.368L252.302 107.384C252.141 107.063 251.712 106.955 251.283 107.063L250.801 107.17C250.372 107.277 250.104 107.599 250.104 107.92L250.157 110.173C250.157 110.602 250.694 110.87 251.23 110.762Z"
          fill="#EB6776"
        />
        <path
          d="M251.122 110.119C251.122 110.119 250.854 113.015 250.8 114.623C250.693 116.178 250.103 135.375 250.049 136.072C250.049 136.769 252.57 142.668 253.052 142.614C253.535 142.561 256.859 137.037 256.859 136.394C256.859 135.75 253.642 117.787 253.267 114.302C252.999 112.103 252.57 109.636 252.57 109.636L251.122 110.119Z"
          fill="#EB6776"
        />
        <path
          d="M258.843 100.896C258.843 100.896 267.101 101.861 268.066 102.29C269.032 102.719 279.595 126.527 282.276 131.353C284.957 136.179 290.963 147.118 291.231 149.424C291.499 151.73 292.089 172.643 292.089 172.643L286.405 173.822C286.405 173.822 283.563 159.183 282.973 157.736C282.223 155.752 277.987 150.282 271.82 138.753C265.653 127.225 262.811 117.841 261.739 113.283C260.72 108.725 258.843 100.896 258.843 100.896Z"
          fill="#6E4E46"
        />
        <path
          d="M262.222 199.239C261.739 196.076 260.291 194.574 260.291 194.574C260.291 194.574 258.361 196.129 256.377 196.183C254.393 196.236 252.999 195.647 252.999 195.647C252.999 195.647 246.403 200.741 244.955 201.76C244.312 201.92 237.716 203.368 237.394 203.475C237.073 203.583 236.697 206.586 236.912 206.8C237.073 206.961 251.551 207.229 251.604 207.068C251.658 206.854 252.033 204.923 252.73 204.977C253.428 205.084 253.749 206.693 254.071 206.8C254.339 206.907 259.487 206.693 261.042 206.586C261.31 206.586 261.471 206.371 261.471 206.103C261.471 204.816 262.704 202.349 262.222 199.239Z"
          fill="#6E4E46"
        />
        <path
          d="M245.652 105.293C245.652 105.293 238.842 105.507 237.77 106.097C236.697 106.741 236.536 127.814 236.161 132.748C235.786 137.681 235.518 142.024 235.518 142.024L243.132 141.059C243.132 141.059 242.81 119.717 243.454 116.071C244.097 112.371 245.652 105.293 245.652 105.293Z"
          fill="#6E4E46"
        />
        <path
          d="M258.843 83.0397C258.843 83.0397 256.109 85.0238 254.017 86.1498C251.926 87.2223 235.518 94.4077 232.515 95.1584C229.512 95.9091 224.418 95.4801 224.418 95.4801L237.555 87.115C237.555 87.115 247.422 83.0397 248.441 82.8789C249.513 82.718 258.843 83.0397 258.843 83.0397Z"
          fill="#5C3E36"
        />
        <path
          d="M230.745 89.7961C230.745 89.7961 231.925 93.7106 233.748 97.3033C235.571 100.842 240.987 106.794 241.416 106.848C241.845 106.902 244.741 105.829 246.886 103.416C249.03 101.003 250.639 97.5714 251.122 94.6221C251.604 91.6729 252.462 88.1875 252.462 88.1875C252.462 88.1875 250.371 85.0238 250.317 84.7556C250.264 84.4875 247.744 82.5571 245.974 82.1281C244.204 81.6992 238.574 81.5383 237.18 82.0745C235.786 82.6107 232.89 83.5759 232.461 84.3267C232.086 85.1846 230.745 89.7961 230.745 89.7961Z"
          fill="#FFBFA2"
        />
        <path
          d="M243.025 83.4151C243.025 83.4151 246.296 89.957 246.939 90.3324C247.636 90.7077 248.28 89.957 248.226 89.2599C248.173 88.9382 247.636 86.8469 248.333 86.1498C249.03 85.4527 251.068 86.7397 251.39 88.1339C251.604 89.0454 251.39 90.3324 251.765 91.1367C252.141 91.941 252.784 93.3352 253.106 92.9599C253.428 92.5845 253.696 88.2411 253.052 85.9353C252.409 83.6296 250.854 81.0557 250.693 81.0021C250.532 80.9485 244.473 79.447 244.097 79.5543C243.722 79.7151 243.025 83.4151 243.025 83.4151Z"
          fill="#A25E4D"
        />
        <path
          d="M240.397 99.2337C240.29 98.8583 240.451 98.1076 241.738 97.3569C243.025 96.6598 243.722 97.4105 244.687 96.9815C245.706 96.4989 246.242 95.5337 246.457 95.641C246.671 95.7482 246.081 99.0728 244.044 99.9308C241.363 101.057 240.612 99.9308 240.397 99.2337Z"
          fill="#A25E4D"
        />
        <path
          d="M240.505 99.1264C240.236 98.8583 239.486 98.5902 238.199 99.3409C236.912 100.092 237.234 101.057 236.322 101.7C235.41 102.344 234.284 102.29 234.231 102.558C234.231 102.773 237.341 104.006 239.164 102.665C241.631 100.896 241.041 99.7163 240.505 99.1264Z"
          fill="#A25E4D"
        />
        <path
          d="M224.364 95.4265C224.579 95.5874 231.818 91.8338 239.432 88.7237C247.046 85.6136 258.414 83.2006 258.843 82.9861C259.594 82.6108 256.913 81.6992 254.822 80.734C252.999 79.9297 250.425 79.0717 249.781 78.9108C249.674 78.8572 249.674 78.8572 249.674 78.8572L249.406 78.9108L248.709 79.0717L238.574 81.3238L229.726 84.9702C229.726 84.9702 229.512 85.3455 229.083 85.989C228.654 86.6861 228.011 87.7585 227.26 88.8846C225.866 91.1367 224.15 95.3193 224.364 95.4265Z"
          fill="#6E4E46"
        />
        <path
          d="M229.083 86.0426L229.244 85.989C229.244 85.989 229.244 85.989 229.297 85.989L249.513 78.9108L249.674 78.8572C249.674 78.8572 249.191 77.9456 248.441 76.6587C247.851 75.6935 247.1 74.5674 246.189 73.4413C244.097 70.8675 242.918 70.0631 241.738 70.3849C240.612 70.7066 238.574 73.8167 237.77 73.9239C236.965 74.0312 232.783 72.3153 231.121 73.2268C228.976 74.4065 228.547 78.3746 228.547 80.7876C228.547 81.5383 228.654 82.5571 228.761 83.5223C228.869 84.8629 229.083 86.0426 229.083 86.0426Z"
          fill="#6E4E46"
        />
        <path
          d="M229.083 86.0426C229.137 86.0426 229.19 85.989 229.244 85.989C229.244 85.989 229.244 85.989 229.297 85.989C231.657 85.0238 236.536 83.0397 238.199 82.4499C240.344 81.6992 246.564 79.7152 248.709 79.1253C248.87 79.0717 248.977 79.0717 249.084 79.0181C249.191 79.0181 249.299 78.9644 249.406 78.9644C249.459 78.9644 249.459 78.9644 249.513 78.9644C249.62 78.9644 249.674 78.9644 249.781 78.9644C249.674 78.9108 249.674 78.9108 249.674 78.9108C249.674 78.9108 249.191 77.9992 248.441 76.7123C245.867 77.4094 241.899 78.5355 238.467 79.6615C234.713 80.8949 230.799 82.6108 228.761 83.5223C228.869 84.8629 229.083 86.0426 229.083 86.0426Z"
          fill="#8A7974"
        />
        <path
          d="M225.169 147.655L226.134 150.282C226.134 150.282 230.424 151.944 230.96 152.373C231.496 152.802 233.426 157.253 233.587 157.95C233.748 158.647 231.764 159.291 231.764 159.291C231.764 159.291 232.247 160.524 232.032 160.792C231.818 161.06 230.853 161.865 230.37 161.865C229.887 161.865 228.922 160.953 228.922 160.953C228.922 160.953 227.045 161.972 226.724 161.65C225.866 160.792 225.169 160.095 225.169 160.095C225.169 160.095 223.56 161.114 223.185 160.578C222.38 159.505 221.093 157.521 221.093 157.521C221.093 157.521 220.128 155.912 220.289 152.427C220.343 151.355 219.538 149.317 219.538 149.317L225.169 147.655Z"
          fill="#FFBFA2"
        />
        <path
          d="M225.598 145.349C225.598 145.349 226.241 147.601 226.08 148.03C225.919 148.459 224.203 149.424 221.469 149.96C220.611 150.121 218.037 150.121 217.715 149.853C217.393 149.585 216.857 147.172 216.857 147.172L225.598 145.349Z"
          fill="#FFE8DB"
        />
        <path
          d="M237.77 106.097C237.77 106.097 207.741 114.623 206.562 116.446C205.382 118.27 213.747 146.689 214.23 146.958C215.57 147.655 219.056 147.279 221.737 146.85C223.345 146.582 226.563 145.724 226.831 144.598C227.099 143.472 219.645 124.436 220.879 124.007C222.112 123.578 236.59 120.575 236.59 120.575L237.77 106.097Z"
          fill="#6E4E46"
        />
        <path
          d="M240.438 128.766C239.684 129.168 238.27 130.664 238.35 131.094C238.431 131.525 240.244 133.85 240.934 133.764C241.623 133.678 243.421 131.866 243.359 131.508C243.28 131.148 241.235 128.34 240.438 128.766Z"
          fill="#635752"
        />
        <path
          d="M243.643 130.678C243.26 130.273 242.62 130.259 242.219 130.647L240.192 132.615C239.8 132.996 239.786 133.621 240.162 134.019L247.675 141.972C248.058 142.377 248.699 142.391 249.099 142.002L251.126 140.035C251.518 139.654 251.532 139.028 251.156 138.631L243.643 130.678Z"
          fill="#8A7974"
        />
        <ellipse
          rx="6.47038"
          ry="8.89677"
          transform="matrix(-0.703457 0.710737 0.710737 0.703457 234.488 124.623)"
          fill="#8A7974"
        />
        <path
          d="M231.467 127.871C234.101 130.478 237.7 131.113 239.505 129.29C241.309 127.466 240.637 123.875 238.003 121.268C235.369 118.661 231.771 118.026 229.966 119.849C228.161 121.673 228.833 125.264 231.467 127.871Z"
          fill="#A7A9AC"
        />
        <ellipse
          rx="1.53146"
          ry="2.21213"
          transform="matrix(-0.580981 0.813917 0.813917 0.580981 234.625 122.88)"
          fill="#D1D4D8"
        />
        <path
          d="M247.1 130.388C247.1 130.388 243.293 131.782 241.899 132.533C240.558 133.284 239.807 134.893 240.022 135.321C240.236 135.75 241.255 136.555 241.255 136.555C241.255 136.555 241.47 138.646 242.113 139.129C242.757 139.665 243.239 139.719 243.561 139.933C243.883 140.148 245.062 140.845 245.652 140.845C246.242 140.845 246.993 141.059 247.797 140.845C248.655 140.63 252.087 136.394 252.087 136.126C252.087 135.858 255.68 135.321 255.68 135.321V129.959C255.68 129.959 250.639 129.745 249.406 129.959C248.119 130.227 247.1 130.388 247.1 130.388Z"
          fill="#FFBFA2"
        />
        <path
          d="M255.68 138.11C255.68 138.11 253.32 138.003 252.999 137.734C252.623 137.466 252.248 135.536 252.623 132.748C252.73 131.89 253.535 129.423 253.91 129.209C254.285 128.994 256.752 129.209 256.752 129.209L255.68 138.11Z"
          fill="#FFE8DB"
        />
        <path
          d="M268.066 102.29C268.066 102.29 276.378 104.381 277.129 105.293C277.879 106.205 292.089 135.429 292.089 136.769C292.089 137.627 288.872 142.185 285.869 142.882C282.866 143.579 256.645 140.362 255.894 140.362C255.143 140.362 255.626 133.23 255.733 131.943C255.894 130.227 256.323 127.385 256.752 127.439L275.413 129.155L268.066 102.29Z"
          fill="#6E4E46"
        />
      </svg>
      <!-- eslint-enable -->
    </div>
    <template slot="title">{{ $t("404.title") }}</template>
    <template slot="description">{{ $t("404.description") }}</template>
    <template slot="button">
      <ez-button
        v-if="isAuthorized"
        size="large"
        type="primary"
        @click.prevent="$router.push({ path: '/' })"
      >{{ $t("404.backButton") }}</ez-button>
      <ez-button
        v-else
        size="large"
        type="primary"
        @click.prevent="login"
      >{{ $t("404.loginButton") }}</ez-button>
    </template>
  </ez-info-box>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  dependencies: ['USER_API'],
  name: 'EzPageNotFound404',
  created() {
    window.document.title = 'EZ Contact - Not found';
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/IS_LOGGED_IN',
    }),
  },
  methods: {
    login() {
      const { protocol, host } = window.location;
      this.USER_API.goToSsoPage(`${protocol}//${host}`);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.error-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}
.error-image {
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 221px;
  &__svg {
    width: 100%;
    height: 100%;
  }
}
</style>
