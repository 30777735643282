import injector from 'vue-inject';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { LOCAL_SETTINGS } from '../../services/names';

import currencyOptions from './currencyOptions';

export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_MAIN_USER = 'SET_MAIN_USER';
export const SET_AVAILABLE_USERS = 'SET_AVAILABLE_USERS';
export const SET_LIMITS = 'SET_LIMITS';
export const SET_TARIFFS = 'SET_TARIFFS';
export const SET_IS_LOADING = 'SET_TARIFFS';
export const SET_EXPIRED = 'SET_EXPIRED';
export const UNSET_EXPIRED = 'UNSET_EXPIRED';
export const SET_USER_AVATARS = 'SET_USER_AVATARS';

// group currencies by id
const currencies = keyBy(currencyOptions, 'index');

const mutations = {
  [SET_IS_LOGGED_IN]: (state, status) => {
    state.isLoggedIn = status;
  },
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user;
    const localSettings = injector.get(LOCAL_SETTINGS);
    if (user != null) {
      localSettings.set('currentUserID', user.id);
    } else {
      localSettings.remove('currentUserID');
    }
  },
  [SET_AVAILABLE_USERS]: (state, users) => {
    state.availableUsers = users;
  },
  [SET_LIMITS]: (state, limits) => {
    state.limits = limits || [];
  },
  [SET_TARIFFS]: (state, tariffs) => {
    state.tariffs = {
      current: tariffs ? tariffs.current : null,
      next: tariffs ? tariffs.next : null,
    };
  },
  [SET_MAIN_USER]: (state, user) => {
    // save main user to state
    state.mainUser = user;
    // get main user currency (1 as EUR by default)
    const currencyId = get(user, 'attributes.currency', null) || 1;
    // save currency to state
    state.currency = currencies[currencyId] || null;
  },

  [SET_IS_LOADING](state, value) {
    state.isLoading = Boolean(value);
  },
  [SET_EXPIRED]: (state) => {
    state.isExpired = true;
  },
  [UNSET_EXPIRED]: (state) => {
    state.isExpired = false;
  },
  [SET_USER_AVATARS]: (state, urls) => {
    state.avatarUrls = urls;
  },
};

export default mutations;
