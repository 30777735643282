import * as mutations from './mutations';

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

const actions = {
  [SET_BREADCRUMBS]: ({ commit }, items = []) => {
    commit(mutations.SET_BREADCRUMBS, items);
  },
};

export default actions;
