import Vue from 'vue';
import { refreshToken } from '@dmant/ez-auth-common';
import './plugins/sentry';
import Notifications from 'vue-notification';
import Ability from '@dmant/ez-lib/src/ability/install';
import QuickStartVideoPlugin from '@dmant/ez-lib/src/quick-start-video';
import VeeValidate from 'vee-validate';
import liveChat from '@dmant/ez-livechat-common';
import App from './App.vue';
import router from './router';
import store from './store';
import injector from './services';
import * as names from './services/names';
import i18n from './i18n';
import './components/common';

Vue.use(Ability);
Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(VeeValidate);
Vue.use(liveChat);
Vue.use(QuickStartVideoPlugin, { router, store });

const vueInstance = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

injector.constant(names.VUE_INSTANCE, vueInstance);
injector.constant(names.VUE_I18N, i18n);

const axios = injector.get(names.AXIOS);
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const apiUser = injector.get(names.USER_API);
    // eslint-disable-next-line
    console.log('response.status in error = ', error);
    const { status, url } = error.response || {};
    if (status === 403) {
      if (!url.endsWith('/identity')) {
        store.commit('auth/SET_EXPIRED');
      }
    } else if (status === 401) {
      apiUser.goToSsoPage();
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

refreshToken();
// display build info to console
// eslint-disable-next-line
console.log(`Build: ${BUILD_INFO.BUILDDATETIME}, ${BUILD_INFO.VERSION}, ${BUILD_INFO.COMMITHASH}, ${BUILD_INFO.BRANCH}`);
