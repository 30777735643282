import injector from 'vue-inject';
import { LOCAL_SETTINGS } from './names';

function localSettings($log) {
  const { localStorage } = window;
  const set = (param, value) => {
    try {
      if (value == null) {
        localStorage.removeItem(param);
      } else {
        const jsonStringValue = JSON.stringify(value);
        localStorage.setItem(param, jsonStringValue);
      }
    } catch (err) {
      $log('[SET LOCAL STORAGE ERROR]', err);
    }
  };
  const get = (param) => {
    try {
      const value = localStorage.getItem(param);
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (err) {
      $log('[GET LOCAL STORAGE ERROR]', err);
      return null;
    }
  };
  const remove = (param) => {
    localStorage.removeItem(param);
  };
  return {
    set,
    get,
    remove,
  };
}
injector.service(LOCAL_SETTINGS, ['$log'], localSettings);
