// axios http library (client for api requests)
export const AXIOS = 'axios';

export const VUE_INSTANCE = 'VUE_INSTANCE';
export const VUE_I18N = 'VUE_I18N';

export const USER_API = 'USER_API';
export const CONTACTS_API = 'CONTACTS_API';
export const TAGS_API = 'TAGS_API';
export const TIMELINE_API = 'TIMELINE_API';
export const PAGE_ACTIVITY_API = 'PAGE_ACTIVITY_API';
export const FUNNELS_API = 'FUNNELS_API';
export const WEBINARS_API = 'WEBINARS_API';
export const PURCHASES_API = 'PURCHASES_API';
export const ACADEMIES_API = 'ACADEMIES_API';

export const FORMATTERS = 'FORMATTERS';
export const FILE_DOWNLOADER = '$FILE_DOWNLOADER';
export const LOCAL_SETTINGS = '$LOCAL_SETTINGS';
