<template>
  <div id="app">
    <app-layout />
    <ez-notice></ez-notice>
  </div>
</template>

<script>
import AppLayout from './components/Layout.vue';

export default {
  components: { AppLayout },
};
</script>

<style lang="scss">
@import "~@dmant/ez-ui-components/src/components/core/src/variables";
@import "~@dmant/ez-ui-components/src/components/core/src/custom/reset";
body {
  overflow-x: hidden;
  font-family: $font-family;
  background-color: #f6f8fa;
  color: $color-default;
}
.fade {
  // Animation page
  &-transition {
    &-enter-active,
    &-leave-active {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: 0.4s;
      opacity: 1;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>
