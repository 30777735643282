const fields = [
  {
    name: 'firstName',
    displayName: 'First name',
    visible: false,
    sortable: true,
  },
  {
    name: 'lastName',
    displayName: 'Last name',
    visible: false,
    sortable: true,
  },
  {
    name: 'email',
    displayName: 'E-mail address',
    visible: false,
    sortable: true,
  },
  {
    name: 'phone',
    displayName: 'Phone',
    visible: false,
    sortable: true,
  },
  {
    name: 'country',
    displayName: 'Country',
    visible: true,
    sortable: true,
  },
  {
    name: 'tags',
    displayName: 'Tags',
    visible: true,
    sortable: false,
    formatter: 'tag',
  },
  {
    name: 'page-visits',
    displayName: 'Page visits',
    visible: true,
    sortable: true,
  },
  {
    name: 'unique-page-visits',
    displayName: 'Unique page visits',
    visible: false,
    sortable: true,
  },
  {
    name: 'webinars-registered',
    displayName: 'Webinars registered (unique)',
    visible: false,
    sortable: true,
  },
  {
    name: 'purchases',
    displayName: 'Purchases',
    visible: false,
    sortable: true,
  },
  {
    name: 'memberships',
    displayName: 'Memberships',
    visible: false,
    sortable: true,
  },
  {
    name: 'completed-lessons',
    displayName: 'Completed lessons',
    visible: false,
    sortable: true,
  },
  {
    name: 'completed-courses',
    displayName: 'Completed courses',
    visible: false,
    sortable: true,
  },
  {
    name: 'type',
    displayName: 'Contact type',
    visible: false,
    sortable: true,
    formatter: 'contact-type',
  },
  {
    name: 'logins',
    displayName: 'Number of logins',
    visible: false,
    sortable: true,
  },
  {
    name: 'sessions',
    displayName: 'Number of sessions',
    visible: false,
    sortable: true,
  },
  {
    name: 'mails-received',
    displayName: 'Mails received',
    visible: false,
    sortable: true,
  },
  {
    name: 'webinars-attended',
    displayName: 'Webinars attended',
    visible: false,
    sortable: true,
  },
  {
    name: 'revenue',
    displayName: 'Revenue',
    visible: true,
    sortable: true,
    formatter: 'currency',
  },
  {
    name: 'optinDate',
    displayName: 'Optin date',
    visible: true,
    sortable: true,
    formatter: 'user-time',
    timeFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  {
    name: 'sourceId1',
    displayName: 'Source #1',
    visible: false,
  },
  {
    name: 'sourceId2',
    displayName: 'Source #2',
    visible: false,
  },
  {
    name: 'sourceId3',
    displayName: 'Source #3',
    visible: false,
  },
  {
    name: 'sourceId4',
    displayName: 'Source #4',
    visible: false,
  },
];

const state = {
  data: {},
  items: [],
  currentItem: null,
  loading: false,
  updating: false,

  totalItems: 0,
  totalPages: 1,
  pageNumber: 1,
  pageSize: 10,

  fields,

  _exports: {
    items: {
      '37d027af-41ae-4acb-8fae-cc13ac4a55cf': {
        id: '37d027af-41ae-4acb-8fae-cc13ac4a55cf',
        status: 'new',
      },
    },
  },

  widgets: [],
  userWidgets: [],
};

try {
  // in-dev storage clear
  // localStorage.clear();
  const value = localStorage.getItem('DASHBOARD_FIELDS_v2023.07.16.v1');
  const parsedValue = JSON.parse(value);
  if (parsedValue.length > 0) {
    state.fields = parsedValue
      .filter((savedField) => fields.find((field) => field.name === savedField.name));
  }
} catch (err) {
  state.fields = fields;
}

export default state;
