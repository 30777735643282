import injector from 'vue-inject';
import AcademiesRepository from '../api/AcademiesRepository';

import * as injectNames from './names';

function api(axios, apiUrl, $log) {
  const options = {
    axios,
    apiUrl,
    $log,
  };

  const repository = new AcademiesRepository(options);

  return repository;
}

injector.service(
  injectNames.ACADEMIES_API,
  [
    injectNames.AXIOS,
    '$log',
  ],
  api,
);
