/* eslint-disable no-shadow */
import data from './timeline.json';

const timeline = {
  namespaced: true,
  state: {
    title: 'activity',
    data: [],
  },
  mutations: {
    GET_DATA(state, { data }) {
      state.data = data;
    },
  },
  actions: {
    getData({ commit }) {
      setTimeout(() => {
        commit('GET_DATA', { data });
      }, 250);
    },
  },
};

export default timeline;
