<template>
  <ez-footer
    :social-links="socialLinks"
    :copyright="$t('footer.copyright')"
    :nav-links="navLinks"
  >
  </ez-footer>
</template>

<script>
import {
  URL_IMPRESSUM,
  URL_TERMS,
  URL_CANCELLATION,
  getFaqLink,
} from '@dmant/ez-env-common';
import localeMixin from '@/mixins/locale';
import { LOCALES_MAP, DE } from '@/enums/locales';

export default {
  mixins: [localeMixin],
  data() {
    return {
      socialLinks: [
        { name: 'facebook', href: 'https://go.ezfunnels.com/facebook' },
        { name: 'instagram', href: 'https://go.ezfunnels.com/instagram' },
        { name: 'youtube', href: 'https://go.ezfunnels.com/youtube' },
        // { name: <String>[, href: <String> ] }
        // names: facebook, instagram, twitter, google-plus, linkedin, youtube
      ],
    };
  },
  computed: {
    navLinks() {
      const { attributes: { locale } = {} } = this.mainUser || {};
      return [{
        name: 'imprint',
        text: this.$t('footer.imprint'),
        href: URL_IMPRESSUM,
      }, {
        name: 'terms',
        text: this.$t('footer.terms'),
        href: URL_TERMS,
      }, {
        name: 'cancellation',
        text: this.$t('footer.cancellation'),
        href: URL_CANCELLATION,
      }, {
        name: 'faq',
        text: this.$t('footer.faq'),
        href: getFaqLink(LOCALES_MAP[locale] || LOCALES_MAP[DE]),
      }];
    },
  },
};
</script>
