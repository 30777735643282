const state = {
  loading: false,

  academies: [],
  members: [],
  courses: [],
  membersCourses: [],
};

export default state;
