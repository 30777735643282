const state = {
  isLoading: true,
  isLoggedIn: false,
  mainUser: null,
  currentUser: null,
  availableUsers: [],
  avatarUrls: {},
  limits: [],
  tariffs: {
    current: null,
    next: null,
  },
  locale: 'en',
  currency: null,
  isExpired: false,
};

export default state;
