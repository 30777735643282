/* eslint-disable no-shadow */
import data from './webinars.json';

const webinars = {
  namespaced: true,
  state: {
    title: 'webinars',
    data: [],
    total: 0,
    totalPages: 0,
    loading: true,
  },
  mutations: {
    GET_DATA(state, { data, total, limit }) {
      state.data = data;
      state.total = total;
      state.totalPages = Math.ceil(total / limit);
      state.loading = false;
    },
  },
  actions: {
    getData({ commit }, { limit, page = 1 }) {
      setTimeout(() => {
        commit('GET_DATA', {
          data: [...data.slice((page - 1) * limit, (page - 1) * limit + limit)],
          total: data.length,
          limit,
        });
      }, 250);
    },
  },
};

export default webinars;
