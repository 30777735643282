import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import axios from 'axios';
import helper from './helpers/Tags';

class TagsRepository {
  constructor({
    axios: axiosInstance, $log,
  }) {
    this.axios = axiosInstance || axios;
    this.$log = $log;
  }

  async getTag(id) {
    const url = `${URL_API_CONTACTS}/v1/tags/${id}`;
    let result = null;
    let response = null;

    const queryParams = {
      include: 'contacts',
    };

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = helper.fromJsonApi(response.data.data);
    }

    return result;
  }

  async getTags(params) {
    const { pageSize = 100, pageNumber = 1, userId = null } = params || {};

    const url = `${URL_API_CONTACTS}/v1/tags`;
    const result = { contacts: null, meta: null };
    let response = null;

    const queryParams = {
      'page[size]': pageSize,
      'page[number]': pageNumber,
      sort: 'name',
    };
    if (userId != null) {
      queryParams['filter[user][eq]'] = userId;
    }

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result.contacts = response.data.data.map((item) => helper.fromJsonApi(item));
      result.meta = response.data.meta;
    }

    return result;
  }

  async createTag(item) {
    const url = `${URL_API_CONTACTS}/v1/tags`;
    let result = null;
    let response = null;

    try {
      const data = helper.toJsonApi(item);
      response = await this.axios.post(url, data, { withCredentials: true });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = helper.fromJsonApi(response.data.data);
    }

    return result;
  }
}

export default TagsRepository;
