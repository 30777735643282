/* eslint-disable no-underscore-dangle */
export const DATA = 'DATA';
export const ITEMS = 'ITEMS';
export const CURRENT_ITEM = 'CURRENT_ITEM';
export const LOADING = 'LOADING';
export const UPDATING = 'UPDATING';
export const TOTAL_ITEMS = 'TOTAL_ITEMS';
export const TOTAL_PAGES = 'TOTAL_PAGES';
export const PAGE_NUMBER = 'PAGE_NUMBER';
export const PAGE_SIZE = 'PAGE_SIZE';
export const FIELDS = 'FIELDS';
export const EXPORT_ITEMS = 'EXPORT_ITEMS';
export const WIDGETS = 'WIDGETS';
export const USER_WIDGETS = 'USER_WIDGETS';

const getters = {
  [DATA]: (state) => state.data,
  [CURRENT_ITEM]: (state) => state.currentItem,
  [ITEMS]: (state) => state.items,
  [LOADING]: (state) => state.loading,
  [UPDATING]: (state) => state.updating,
  [TOTAL_ITEMS]: (state) => state.totalItems,
  [TOTAL_PAGES]: (state) => state.totalPages,
  [PAGE_NUMBER]: (state) => state.pageNumber,
  [PAGE_SIZE]: (state) => state.pageSize,
  [FIELDS]: (state) => state.fields,
  [EXPORT_ITEMS]: (state) => state._exports.items,
  [WIDGETS]: (state) => state.widgets,
  [USER_WIDGETS]: (state) => state.userWidgets,
};

export default getters;
