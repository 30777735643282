/* eslint-disable consistent-return */
import injector from 'vue-inject';
import Vue from 'vue';
import * as injectNames from '@/services/names';
import * as authGetters from '../auth/getters';
import * as mutations from './mutations';

export const LOAD_ALL = 'LOAD_ALL';
export const ATTACH_TO_COURSES = 'ATTACH_TO_COURSES';
export const UPDATE_MEMBERS_COURSE_IS_ACTIVE = 'UPDATE_MEMBERS_COURSE_IS_ACTIVE';
export const DELETE_MEMBERS_COURSE = 'DELETE_MEMBERS_COURSE';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const UPDATE_MEMBER_PASSWORD = 'UPDATE_MEMBER_PASSWORD';

const actions = {
  [LOAD_ALL]: async ({ rootGetters, commit }, contactId) => {
    const api = injector.get(injectNames.ACADEMIES_API);
    const user = rootGetters[`auth/${authGetters.CURRENT_USER}`];
    const userId = user ? user.legacyId : null;
    try {
      const requests = [api.getAcademies(userId), api.getMembers(contactId)];
      const [academies, members] = await Promise.all(requests);
      commit(mutations.SET_ACADEMIES, academies.items);
      commit(mutations.SET_MEMBERS, members.items);
      const membersIds = members.items.map((item) => item.id);
      if (membersIds?.length) {
        const resp = await api.getMemberCourses(membersIds);
        commit(mutations.SET_MEMBERS_COURSES, resp.items);
      }
    } catch (err) {
      const $log = injector.get('$log');
      $log({ err });
    } finally {
      commit(mutations.SET_LOADING, false);
    }
  },

  [ATTACH_TO_COURSES]: async ({ commit, dispatch }, {
    academy, courses, packages, contactId, isNotification,
  }) => {
    // get api from injector
    const api = injector.get(injectNames.ACADEMIES_API);
    const vueInstance = injector.get(injectNames.VUE_INSTANCE);

    commit(mutations.SET_LOADING, true);

    const request = api.createOrder(academy, courses, packages, contactId, isNotification);

    try {
      await request;
      await dispatch(LOAD_ALL, contactId);
      commit(mutations.SET_LOADING, false);

      const message = vueInstance.$t('notifications.memberships-course-attached');
      Vue.notify({
        data: {
          type: 'success', content: message,
        },
      });
    } catch (err) {
      commit(mutations.SET_LOADING, false);
      return err;
    }
  },

  [UPDATE_MEMBERS_COURSE_IS_ACTIVE]: async ({ dispatch }, {
    memberCourseId, isActive, contactId,
  }) => {
    // get api from injector
    const api = injector.get(injectNames.ACADEMIES_API);
    const vueInstance = injector.get(injectNames.VUE_INSTANCE);
    const $log = injector.get('$log');
    try {
      await api.updateMemberCourseIsActive(memberCourseId, isActive);
      await dispatch(LOAD_ALL, contactId);

      const message = vueInstance.$t(isActive ? 'notifications.memberships-course-enabled' : 'notifications.memberships-course-disabled');
      Vue.notify({
        data: {
          type: 'success', content: message,
        },
      });
    } catch (err) {
      $log(err);
    }
  },

  [DELETE_MEMBERS_COURSE]: ({ dispatch }, { memberCourseId, contactId }) => {
    const api = injector.get(injectNames.ACADEMIES_API);
    const vueInstance = injector.get(injectNames.VUE_INSTANCE);
    const $log = injector.get('$log');
    api
      .deleteMemberCourse(memberCourseId)
      .then(() => {
        dispatch(LOAD_ALL, contactId);
        const message = vueInstance.$t('notifications.memberships-course-deleted');
        Vue.notify({
          data: {
            type: 'success', content: message,
          },
        });
      })
      .catch((err) => {
        $log(err);
      });
  },

  [DELETE_MEMBER]: ({ dispatch }, { memberId, contactId }) => {
    const api = injector.get(injectNames.ACADEMIES_API);
    const vueInstance = injector.get(injectNames.VUE_INSTANCE);
    const $log = injector.get('$log');
    api
      .deleteMember(memberId)
      .then(() => {
        dispatch(LOAD_ALL, contactId);
        const message = vueInstance.$t('notifications.memberships-member-deleted');
        Vue.notify({
          data: {
            type: 'success', content: message,
          },
        });
      })
      .catch((err) => {
        $log(err);
      });
  }, // eslint-disable-next-line
  [UPDATE_MEMBER_PASSWORD]: ({ dispatch }, { memberId, password, isNotification }) => {
    const api = injector.get(injectNames.ACADEMIES_API);
    const vueInstance = injector.get(injectNames.VUE_INSTANCE);
    const $log = injector.get('$log');
    api
      .updateMemberPassword(memberId, password, isNotification)
      .then(() => {
        const message = vueInstance.$t('notifications.memberships-password-updated');
        Vue.notify({
          data: {
            type: 'success', content: message,
          },
        });
      })
      .catch((err) => {
        $log(err);
      });
  },
};

export default actions;
