export const SET_LOADING = 'SET_LOADING';
export const SET_ACADEMIES = 'SET_ACADEMIES';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_COURSES = 'SET_COURSES';
export const SET_MEMBERS_COURSES = 'SET_MEMBERS_COURSES';

const mutations = {
  [SET_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [SET_ACADEMIES]: (state, academies) => {
    state.academies = academies;
  },
  [SET_MEMBERS]: (state, members) => {
    state.members = members;
  },
  [SET_COURSES]: (state, courses) => {
    state.courses = courses;
  },
  [SET_MEMBERS_COURSES]: (state, membersCourses) => {
    state.membersCourses = membersCourses;
  },
};

export default mutations;
