/* eslint-disable no-shadow */
import data from './membership.json';

const membership = {
  namespaced: true,
  state: {
    title: 'academies',
    data: [],
  },
  mutations: {
    GET_DATA(state, { data }) {
      state.data = data;
    },
  },
  actions: {
    getData({ commit }) {
      commit('GET_DATA', { data });
    },
  },
};

export default membership;
