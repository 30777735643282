import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import axios from 'axios';
import processResponse from './helpers/processResponse';

class PageActivityRepository {
  constructor({
    axios: axiosInstance, $log,
  }) {
    this.axios = axiosInstance || axios;
    this.$log = $log;
  }

  async getPageActivities(params) {
    const { contactId, pageSize = 20, pageNumber = 1 } = params || {};

    const url = `${URL_API_CONTACTS}/v1/statistic/page-activity`;

    const queryParams = {
      'page[size]': pageSize,
      'page[number]': pageNumber,
      'filter[page-activity][and][contact][eq]': contactId,
    };

    const result = { data: null, meta: null };
    let response = null;

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result.data = processResponse(response.data);
      result.meta = response.data.meta;
    }

    return result;
  }
}

export default PageActivityRepository;
