import injector from 'vue-inject';
import PurchasesRepository from '../api/PurchasesRepository';

import * as injectNames from './names';

function api(axios, $log) {
  const options = {
    axios,
    $log,
  };

  const repository = new PurchasesRepository(options);

  return repository;
}

injector.service(
  injectNames.PURCHASES_API,
  [
    injectNames.AXIOS,
    '$log',
  ],
  api,
);
