import { URL_API_USER, URL_AUTH } from '@dmant/ez-env-common';
import injector from 'vue-inject';
import * as names from './names';

function apiUser(axios) {
  const goToSsoPage = (continuePage = window.location.href) => {
    const goToUrl = continuePage
      ? `${URL_AUTH}?continue=${continuePage}`
      : URL_AUTH;
    window.location.href = goToUrl;
  };

  const logout = async () => {
    axios.get(`${URL_API_USER}/v1/logout`, {
      withCredentials: true,
    });
  };

  return {
    goToSsoPage,
    logout,
  };
}

injector.service(names.USER_API, [names.AXIOS], apiUser);
