/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import get from 'lodash/get';
import injector from 'vue-inject';

export const SET_ITEMS = 'SET_ITEMS';
export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
export const APPEND_ITEM_TO_DATA = 'APPEND_ITEM_TO_DATA';
export const SET_LOADING = 'SET_LOADING';
export const SET_UPDATING = 'SET_UPDATING';
export const SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_EXPORT_ITEM = 'SET_EXPORT_ITEM';
export const SET_WIDGETS = 'SET_WIDGETS';
export const SET_USER_WIDGETS = 'SET_USER_WIDGETS';

const mutations = {
  [SET_ITEMS]: (state, items) => {
    state.items = items;
    if (items && items.length > 0) {
      for (const item of items) {
        state.data[item.id] = item;
      }
    }
  },
  [APPEND_ITEM_TO_DATA]: (state, item) => {
    if (item) {
      state.data[item.id] = item;
    }
  },
  [SET_CURRENT_ITEM]: (state, item) => {
    state.currentItem = item;
  },
  [SET_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [SET_UPDATING]: (state, updating) => {
    state.updating = updating;
  },
  [SET_TOTAL_ITEMS]: (state, count) => {
    state.totalItems = count;
  },
  [SET_TOTAL_PAGES]: (state, count) => {
    state.totalPages = count;
  },
  [SET_PAGE_SIZE]: (state, size) => {
    state.pageSize = size;
  },
  [SET_PAGE_NUMBER]: (state, pageNumber) => {
    state.pageNumber = pageNumber;
  },
  [SET_FIELDS]: (state, fields) => {
    // state.fields;
    // save fields to local storage
    try {
      localStorage.setItem(
        'DASHBOARD_FIELDS_v2021.03.10.v1',
        JSON.stringify(fields),
      );
    } catch (err) {
      const $log = injector.get('$log');
      $log({ err });
    }
  },
  [SET_EXPORT_ITEM]: (state, payload) => {
    const { id, item } = payload;
    const value = get(state._exports, `items['${id}']`, {});
    const items = get(state._exports, 'items', {});

    state._exports.items = {
      ...items,
      [id]: {
        ...value,
        ...item,
      },
    };
  },
  [SET_WIDGETS]: (state, widgets) => {
    // hide activity level
    state.widgets = widgets.filter((item) => item.type !== 'AVERAGE_ACTIVITY_LEVEL');
  },
  [SET_USER_WIDGETS]: (state, userWidgets) => {
    state.userWidgets = userWidgets;
  },
};

export default mutations;
