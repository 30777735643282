export const LOADING = 'LOADING';
export const ACADEMIES = 'ACADEMIES';
export const MEMBERS = 'MEMBERS';
export const COURSES = 'COURSES';
export const MEMBERS_COURSES = 'MEMBERS_COURSES';

const getters = {
  [LOADING]: (state) => state.loading,
  [ACADEMIES]: (state) => state.academies,
  [MEMBERS]: (state) => state.members,
  [COURSES]: (state) => state.courses,
  [MEMBERS_COURSES]: (state) => state.membersCourses,
};

export default getters;
