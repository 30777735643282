export const DATA_TYPE = 'contacts';

/**
 * convert JSON string property value to object
 * @param {Object} obj source object
 * @param {string} property propetry to deserialize
 */
const deserializeJsonField = (obj, property) => {
  try {
    const jsonStringValue = obj[property];
    const jsonObjValue = JSON.parse(jsonStringValue);
    return { [property]: jsonObjValue };
  } catch (_) {
    return null;
  }
};

/**
 * Serialize obj property value to JSON string
 * @param {Object} obj object to pick property value from
 * @param {string} property property to serialize
 */
// eslint-disable-next-line
const serializeJsonField = (obj, property) => obj && obj[property] && { [property]: JSON.stringify(obj[property]) };

const fromJsonApi = (data, included = []) => {
  const { attributes, id } = data;

  const result = {
    id: id || attributes.id,
    firstName: attributes['first-name'],
    lastName: attributes['last-name'],
    email: attributes.email,
    isPresenter: attributes['is-presenter'],
    presenterId: attributes['presenter-id'],
    purchaseScore: attributes['purchase-score'],
    subscribeStatus: attributes['subscribe-status'],
    ip: attributes.ip,
    optinDate: attributes['optin-date'],
    notes: attributes.notes,
    lock: attributes.lock,
    country: attributes.country,
    city: attributes.city,
    addr: attributes.addr,
    state: attributes.state,
    zip: attributes.zip,
    phone: attributes.phone,
    dtCreate: attributes['dt-create'],
    confirmedAt: attributes['confirmed-at'],
    dtUpdate: attributes['dt-update'],
    type: attributes.type,
    'page-visits': attributes['page-visits'],
    'unique-page-visits': attributes['unique-page-visits'],
    'webinars-registered': attributes['webinars-registered'],
    'webinars-attended': attributes['webinars-attended'],
    sourceId1: attributes['source-id1'],
    sourceId2: attributes['source-id2'],
    sourceId3: attributes['source-id3'],
    sourceId4: attributes['source-id4'],
    purchases: attributes.purchases,
    memberships: attributes.memberships,
    'completed-lessons': attributes['completed-lessons'],
    'completed-courses': attributes['completed-courses'],
    'activity-level': attributes['activity-level'],
    logins: attributes.logins,
    sessions: attributes.sessions,
    'mails-received': attributes['mails-received'],
    'shopping-behavior': attributes['shopping-behavior'],
    revenue: attributes.revenue,
    tags: [],
    users: [],
    customerTypeDivision: null,
    attributes,
    ...deserializeJsonField(attributes, 'additional-fields'),
  };

  // extract relations (tags)
  const { relationships } = data;
  if (relationships) {
    const {
      tags,
      'customer-type-division': customerTypeDivision,
    } = relationships;
    // tags
    if (tags && tags.data && tags.data.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const obj of tags.data) {
        result.tags.push(obj.id);
      }
    }
    // customer type division
    if (
      customerTypeDivision
      && customerTypeDivision.data
      && customerTypeDivision.data.length > 0
    ) {
      const [item] = customerTypeDivision.data;
      if (item && item.id) {
        result.customerTypeDivision = included.find((obj) => obj.id === item.id && obj.type === 'customer-type-division');
      }
    }
  }

  return result;
};

const toJsonApi = (item) => {
  const { id } = item;

  const attributes = {
    'first-name': item.firstName,
    'last-name': item.lastName,
    email: item.email,
    'is-presenter': item.isPresenter,
    'presenter-id': item.presenterId,
    'purchase-score': item.purchaseScore,
    'subscribe-status': item.subscribeStatus,
    ip: item.ip,
    'optin-date': item.optinDate,
    notes: item.notes,
    lock: item.lock,
    country: item.country,
    city: item.city,
    addr: item.addr,
    state: item.state,
    zip: item.zip,
    phone: item.phone,
    'dt-create': item.dtCreate,
    'confirmed-at': item.confirmedAt,
    'dt-update': item.dtUpdate,
    type: item.type,
    'page-visits': item['page-visits'],
    'unique-page-visits': item['unique-page-visits'],
    'webinars-registered': item['webinars-registered'],
    'webinars-attended': item['webinars-attended'],
    purchases: item.purchases,
    memberships: item.memberships,
    'completed-lessons': item['completed-lessons'],
    'completed-courses': item['completed-courses'],
    'activity-level': item['activity-level'],
    logins: item.logins,
    sessions: item.sessions,
    'mails-received': item['mails-received'],
    revenue: item.revenue,
    'shopping-behavior': item['shopping-behavior'],
    ...serializeJsonField(item, 'additional-fields'),
  };

  const newAttributes = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(attributes)) {
    const value = attributes[key];
    if (typeof value !== 'undefined') {
      newAttributes[key] = value;
    }
  }

  return {
    data: Object.assign(id ? { id } : {}, {
      attributes: newAttributes,
      type: DATA_TYPE,
    }),
  };
};

export default {
  fromJsonApi,
  toJsonApi,
};
