import injector from 'vue-inject';
import * as injectNames from '@/services/names';

import * as mutations from './mutations';

export const GET_ITEMS = 'GET_ITEMS';

const actions = {
  [GET_ITEMS]: async (
    { commit },
    {
      contactId,
      pageSize = 2,
      pageNumber = 1,
      sort = 'last-name',
      sortProp = 'name',
      sortOrder = 'ascending',
    } = {},
  ) => {
    const api = injector.get(injectNames.TIMELINE_API);
    const $log = injector.get('$log');

    const params = {
      contactId,
      pageSize,
      pageNumber,
      sort,
      sortProp,
      sortOrder,
    };

    commit(mutations.SET_LOADING, true);
    api
      .getPageActivities(params)
      .then((resp) => {
        const { items } = resp;
        // const meta = resp.meta || {};
        // commit(mutations.SET_TOTAL_ITEMS, meta.count);
        // commit(mutations.SET_PAGE_NUMBER, pageNumber);
        // commit(mutations.SET_TOTAL_PAGES, meta.pages);
        // commit(mutations.SET_PAGE_SIZE, pageSize);
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, items);
      })
      .catch((err) => {
        $log(err);
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, []);
      });
  },
};

export default actions;
