import { mapState } from 'vuex';

const defaultLocale = 'en_US';

export default {
  computed: {
    ...mapState({
      mainUser: (state) => state.auth.mainUser,
    }),
    locale() {
      return this.mainUser?.locale || defaultLocale;
    },
  },
};
