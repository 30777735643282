import injector from 'vue-inject';
import FunnelsRepository from '../api/FunnelsRepository';

import * as injectNames from './names';

function api(axios, $log) {
  const options = {
    axios,
    $log,
  };

  const repository = new FunnelsRepository(options);

  return repository;
}

injector.service(
  injectNames.FUNNELS_API,
  [
    injectNames.AXIOS,
    '$log',
  ],
  api,
);
