<template>
  <ez-layout :showQuickStartPanel="showQuickStartPanel" name="contacts">
    <template slot="header">
      <contacts-header />
    </template>

    <template slot="content">
      <ez-preloader
        v-if="isLoading"
        value
        :stop="false"
        class="loader"
      />
      <router-view v-else />
    </template>

    <template slot="footer">
      <contacts-footer />
    </template>
    <ModalAuth
      v-if="isExpired"
      @success="onSuccessAuth"
    />
    <template #quick>
      <QuickStartVideo />
    </template>
  </ez-layout>
</template>

<script>
import { mapState } from 'vuex';
import { ModalAuth } from '@dmant/ez-auth-common';
import { QuickStartVideo } from '@dmant/ez-lib/src/quick-start-video';
import ContactsHeader from './Header.vue';
import ContactsFooter from './Footer.vue';

export default {
  components: {
    ContactsFooter,
    ContactsHeader,
    ModalAuth,
    QuickStartVideo,
  },
  computed: {
    ...mapState('auth', ['isLoading', 'isExpired']),
    showQuickStartPanel() {
      return this.$store.getters[`${this.$quickStartVideo.moduleName}/show`];
    },
  },
  methods: {
    onSuccessAuth() {
      this.$store.commit('auth/UNSET_EXPIRED');
    },
  },
};
</script>

<style lang="scss">
@import "~element-ui/lib/theme-chalk/index.css";
@import "~@dmant/ez-ui-components/src/styles/src/contacts/index.scss";
</style>

<style lang="scss" scoped>
 .loader {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-grow: 1;
   ::v-deep .preloader__spinner {
     position: initial;
   }
 }
</style>
