import injector from 'vue-inject';
import WebinarsRepository from '../api/WebinarsRepository';

import * as injectNames from './names';

function api(axios, $log) {
  const options = {
    axios,
    $log,
  };

  const repository = new WebinarsRepository(options);

  return repository;
}

injector.service(
  injectNames.WEBINARS_API,
  [
    injectNames.AXIOS,
    '$log',
  ],
  api,
);
