const state = {
  data: {},
  items: [],
  currentItem: null,
  loading: false,

  totalItems: 0,
  totalPages: 1,
  pageNumber: 1,
  pageSize: 10,
};

export default state;
