import get from 'lodash/get';

export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const CURRENT_USER = 'CURRENT_USER';
export const MAIN_USER = 'MAIN_USER';
export const AVAILABLE_USERS = 'AVAILABLE_USERS';
export const AVATAR_URLS = 'AVATAR_URLS';
export const LIMITS = 'LIMITS';
export const CURRENT_TARIFF = 'CURRENT_TARIFF';

const getters = {
  [IS_LOGGED_IN]: (state) => state.isLoggedIn,
  [CURRENT_USER]: (state) => state.currentUser,
  [MAIN_USER]: (state) => state.mainUser,
  [AVAILABLE_USERS]: (state) => state.availableUsers,
  [AVATAR_URLS]: (state) => state.avatarUrls,
  [LIMITS]: (state) => state.limits,
  [CURRENT_TARIFF]: (state) => {
    const { locale = 'en' } = state;
    return get(state, `tariffs.current.data.attributes.name-${locale}`, ' ');
  },
};

export default getters;
