/**
 * Common global components
 */
import Vue from 'vue';

import Badge from '@dmant/ez-ui-components/src/components/badge';
import Core from '@dmant/ez-ui-components/src/components/core';
import Container from '@dmant/ez-ui-components/src/components/container';
import Checkbox from '@dmant/ez-ui-components/src/components/checkbox';
import Row from '@dmant/ez-ui-components/src/components/row';
import Col from '@dmant/ez-ui-components/src/components/col';
import Logotype from '@dmant/ez-ui-components/src/components/logotype';
import Icon from '@dmant/ez-ui-components/src/components/icon';
import ButtonGroup from '@dmant/ez-ui-components/src/components/button-group';
import Button from '@dmant/ez-ui-components/src/components/button';
import Tabs from '@dmant/ez-ui-components/src/components/tabs';
import Input from '@dmant/ez-ui-components/src/components/input';
import Switch from '@dmant/ez-ui-components/src/components/switch';
import Form from '@dmant/ez-ui-components/src/components/form';
import FormItem from '@dmant/ez-ui-components/src/components/form-item';
import DropdownUser from '@dmant/ez-ui-components/src/components/dropdown-user';
import Layout from '@dmant/ez-ui-components/src/components/layout';
import Header from '@dmant/ez-ui-components/src/components/header';
import HeaderBar from '@dmant/ez-ui-components/src/components/header-bar';
import Link from '@dmant/ez-ui-components/src/components/link';
import Dropdown from '@dmant/ez-ui-components/src/components/dropdown';
import DropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import DropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import Facts from '@dmant/ez-ui-components/src/components/facts';
import Table from '@dmant/ez-ui-components/src/components/table';
import TableColumn from '@dmant/ez-ui-components/src/components/table/table-column';
import Footer from '@dmant/ez-ui-components/src/components/footer';
import SubHeader from '@dmant/ez-ui-components/src/components/sub-header';
import Pagination from '@dmant/ez-ui-components/src/components/pagination';
import Select from '@dmant/ez-ui-components/src/components/select';
import Timeline from '@dmant/ez-ui-components/src/components/timeline';
import TimelineItem from '@dmant/ez-ui-components/src/components/timeline/timeline-item';
import Textarea from '@dmant/ez-ui-components/src/components/textarea';
import Dialog from '@dmant/ez-ui-components/src/components/dialog';
import FormGroup from '@dmant/ez-ui-components/src/components/form-group';
import Box from '@dmant/ez-ui-components/src/components/box';
import FilterExport from '@dmant/ez-ui-components/src/components/filter-export';
import Icons from '@dmant/ez-ui-components/src/components/icons';
import DropArea from '@dmant/ez-ui-components/src/components/drop-area';
import Preloader from '@dmant/ez-ui-components/src/components/preloader';
import Notification from '@dmant/ez-ui-components/src/components/notification';
import Content from '@dmant/ez-ui-components/src/components/content';
import Notice from '@dmant/ez-ui-components/src/components/notice';
import EmptyPage from '@dmant/ez-ui-components/src/components/empty-page';
import InfoBox from '@dmant/ez-ui-components/src/components/info-box';

import StubBox from './StubBox/StubBox.vue';

Vue.use(Badge);
Vue.use(Container);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Logotype);
Vue.use(Icon);
Vue.use(Icons);
Vue.use(ButtonGroup);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(Input);
Vue.use(Switch);
Vue.use(FormItem);

Vue.use(DropdownUser);
Vue.use(Layout);
Vue.use(Header);
Vue.use(HeaderBar);
Vue.use(Link);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Facts);
Vue.use(Table);
Vue.use(Footer);
Vue.use(SubHeader);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Textarea);
Vue.use(Dialog);
Vue.use(FormGroup);
Vue.use(Form);
Vue.use(Content);
Vue.use(Box);
Vue.use(FilterExport);
Vue.use(TableColumn);
Vue.use(Core);
Vue.use(DropArea);
Vue.use(Preloader);
Vue.use(Notification);
Vue.use(Notice);
Vue.use(EmptyPage);
Vue.use(InfoBox);

Vue.component('app-stub-box', StubBox);
