import injector from 'vue-inject';
import ContactsRepository from '../api/ContactsRepository';

import * as injectNames from './names';

function api(axios, $log) {
  const options = {
    axios,
    $log,
  };

  const repository = new ContactsRepository(options);

  return repository;
}

injector.service(
  injectNames.CONTACTS_API,
  [
    injectNames.AXIOS,
    '$log',
  ],
  api,
);
