import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import axios from 'axios';
import find from 'lodash/find';
import get from 'lodash/get';

export const sortMetaData = {
  date: {
    field: 'dt-create',
    isDefaultSortColumn: true,
    defaultSortDirection: 'descending',
  },
  product: {
    field: 'product-name',
    defaultSortDirection: 'ascending',
  },
  amount: {
    field: 'amount',
    defaultSortDirection: 'ascending',
  },
  transactionType: {
    field: 'transaction-type',
    defaultSortDirection: 'ascending',
  },
  status: {
    field: 'transaction-status',
    defaultSortDirection: 'ascending',
  },
  transactionId: {
    field: 'transaction-id',
    defaultSortDirection: 'descending',
  },
  source: {
    field: 'source',
    defaultSortDirection: 'ascending',
  },
};

class PurchasesRepository {
  constructor({
    axios: axiosInstance, $log,
  }) {
    this.axios = axiosInstance || axios;
    this.$log = $log;
  }

  async getPurchases(params) {
    const defaultSortColumn = find(
      sortMetaData, (value) => !!value.isDefaultSortColumn,
    );

    const {
      contactId,
      pageSize = 2,
      pageNumber = 1,
      sortProp = 'date',
      sortOrder = defaultSortColumn.defaultSortDirection,
    } = params || {};

    const sortColumn = sortMetaData[sortProp] || defaultSortColumn;

    const url = `${URL_API_CONTACTS}/v1/statistic/purchases`;

    const queryParams = {
      'page[size]': pageSize,
      'page[number]': pageNumber,
      'filter[purchases][and][contact][eq]': contactId,
      sort: `${sortOrder === 'descending' ? '-' : ''}${sortColumn.field}`,
    };

    const result = { contacts: null, meta: null };
    let response = null;

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      const items = get(response, 'data.data') || [];
      const meta = get(response, 'data.meta');
      result.items = items.map((item) => item);
      result.meta = meta;
    }

    return result;
  }
}

export default PurchasesRepository;
