import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import axios from 'axios';

class WebinarsRepository {
  constructor({
    axios: axiosInstance, $log,
  }) {
    this.axios = axiosInstance || axios;
    this.$log = $log;
  }

  async getWebinars(params) {
    const { contactId, pageSize = 2, pageNumber = 1 } = params || {};

    const url = `${URL_API_CONTACTS}/v1/statistic/webinars`;

    const queryParams = {
      include: 'webinars',
      'page[size]': pageSize,
      'page[number]': pageNumber,
      'filter[webinars][and][contact][eq]': contactId,
    };

    let result = null;
    let response = null;

    try {
      response = await this.axios.get(url, {
        withCredentials: true,
        params: queryParams,
      });
    } catch (err) {
      this.$log(err);
    }

    if (response && response.status === 200) {
      result = response.data;
    }

    return result;
  }
}

export default WebinarsRepository;
