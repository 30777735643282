import injector from 'vue-inject';
import * as injectNames from '@/services/names';

import * as mutations from './mutations';

import * as authGetters from '../auth/getters';

export const GET_ITEMS = 'GET_ITEMS';
export const APPEND_ITEM = 'APPEND_ITEM';

const actions = {
  [GET_ITEMS]: async (
    { commit, rootGetters },
    { pageSize = 100, pageNumber = 1 } = {},
  ) => {
    const api = injector.get(injectNames.TAGS_API);

    const params = {
      pageSize,
      pageNumber,
    };

    const mainUser = rootGetters[`auth/${authGetters.MAIN_USER}`];
    const { legacyId: mainUserId = null } = mainUser || {};
    params.userId = mainUserId;

    commit(mutations.SET_LOADING, true);
    api
      .getTags(params)
      .then((resp) => {
        const { contacts } = resp;
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, contacts);
      })
      .catch(() => {
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, []);
      });
  },
  [APPEND_ITEM]: ({ commit }, item) => {
    commit(mutations.APPEND_ITEM_TO_DATA, item);
    commit(mutations.APPEND_ITEM_TO_ITEMS, item);
  },
};

export default actions;
