import injector from 'vue-inject';
import * as injectNames from '@/services/names';

import * as mutations from './mutations';

export const GET_ITEMS = 'GET_ITEMS';

const actions = {
  [GET_ITEMS]: async (
    { commit },
    {
      contactId,
      pageSize = 10,
      pageNumber = 1,
      sort = 'last-name',
      searchText = '',
      sortProp,
      sortOrder,
    } = {},
  ) => {
    const api = injector.get(injectNames.PURCHASES_API);

    const params = {
      contactId,
      pageSize,
      pageNumber,
      sort,
      searchText,
      sortProp,
      sortOrder,
    };

    commit(mutations.SET_LOADING, true);
    api
      .getPurchases(params)
      .then((resp) => {
        const { items, meta } = resp;
        commit(mutations.SET_TOTAL_ITEMS, meta.count);
        commit(mutations.SET_PAGE_NUMBER, pageNumber);
        commit(mutations.SET_TOTAL_PAGES, meta.pages);
        commit(mutations.SET_PAGE_SIZE, pageSize);
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, items);
      })
      .catch(() => {
        commit(mutations.SET_LOADING, false);
        commit(mutations.SET_ITEMS, []);
      });
  },
};

export default actions;
