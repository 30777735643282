import updateAbility from '@dmant/ez-lib/src/ability/update';
import { URL_APP, SERVICE_UUID } from '@dmant/ez-env-common';

import injector from 'vue-inject';
// import router from '@/router';

import Vue from 'vue';
import get from 'lodash/get';
import * as injectNames from '@/services/names';
import Api from '../../api/Api';
import * as mutations from './mutations';

export const VALIDATE_SESSION = 'VALIDATE_SESSION';
export const LOGOUT = 'LOGOUT';
export const FAIL_AUTH = 'FAIL_AUTH';
export const SUCCESSFUL_AUTH = 'SUCCESSFUL_AUTH';
export const CHANGE_USER = 'CHANGE_USER';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const USER_AVATARS = 'USER_AVATARS';

const notifyLoggedIn = (user) => {
  if (user != null) {
    let displayName = user.name;
    if (displayName == null && user.attributes != null) {
      const firstName = get(user, 'attributes.first-name', '');
      const lastName = get(user, 'attributes.last-name', '');
      if (firstName !== '' || lastName !== '') {
        displayName = `${firstName} ${lastName}`.trim();
      }
    }
    if (displayName != null && displayName !== '') {
      const vueInstance = injector.get(injectNames.VUE_INSTANCE);
      const message = vueInstance.$t('notifications.logged-in', {
        userName: displayName,
      });
      Vue.notify({
        data: {
          type: 'contacts',
          content: message,
        },
      });
    }
  }
};

const actions = {
  [VALIDATE_SESSION]: async ({ dispatch }) => {
    const apiUser = injector.get(injectNames.USER_API);
    try {
      const { data } = await Api.Users.identity();
      data.wizard = true; // off wizard
      if (!data.wizard) {
        window.location = `${URL_APP}/wizard/`;
        return undefined;
      }
      return dispatch(SUCCESSFUL_AUTH, data);
    } catch (e) {
      console.log(e);
      apiUser.goToSsoPage();
    }

    return undefined;
  },
  [LOGOUT]: async ({ dispatch }) => {
    const apiUser = injector.get(injectNames.USER_API);
    await apiUser.logout();
    dispatch(FAIL_AUTH);
    apiUser.goToSsoPage('');
  },
  [FAIL_AUTH]: async ({ commit }) => {
    commit(mutations.SET_IS_LOGGED_IN, false);
    commit(mutations.SET_CURRENT_USER, null);
    commit(mutations.SET_AVAILABLE_USERS, []);
    await updateAbility(null);
  },
  [SUCCESSFUL_AUTH]: async ({ commit, dispatch }, user) => {
    const i18n = injector.get(injectNames.VUE_I18N);

    const userLocale = user.locale;
    i18n.locale = userLocale;

    await updateAbility(user.id);

    const [limits, tariffs] = await Promise.all([
      Api.Users.Services.getLimits({
        serviceId: SERVICE_UUID,
        userId: user.legacyId,
      }),
      Api.Users.getTariff(user.legacyId),
    ]);

    const fullUsersList = [user];

    // fetch last used user from local storage
    const localSettings = injector.get(injectNames.LOCAL_SETTINGS);
    const currentUserId = localSettings.get('currentUserID');
    let currentUser = user;
    if (currentUserId != null) {
      const savedUsed = fullUsersList.find((item) => item.id === currentUserId);
      currentUser = savedUsed || user;
    }

    commit(mutations.SET_MAIN_USER, user);
    commit(mutations.SET_CURRENT_USER, currentUser);
    commit(mutations.SET_IS_LOGGED_IN, true);
    commit(mutations.SET_AVAILABLE_USERS, fullUsersList);
    commit(mutations.SET_LIMITS, limits);
    commit(mutations.SET_TARIFFS, tariffs);
    dispatch(USER_AVATARS, fullUsersList);
    notifyLoggedIn(currentUser);
  },
  async [USER_AVATARS]({ commit }, users) {
    const getFile = async (cloudId) => {
      if (cloudId) {
        try {
          const data = await Api.Cloud.Files.getOne(cloudId);
          return data?.data;
        } catch (error) {
          console.log(error);
        }
      }
      return undefined;
    };
    const files = await Promise.all(users.map(({ avatar }) => getFile(avatar)));
    const result = files.filter(Boolean).reduce((obj, { id, url }) => {
      obj[id] = url;
      return obj;
    }, {});
    commit(mutations.SET_USER_AVATARS, result);
  },
  [CHANGE_USER]: async ({ commit }, { user, router }) => {
    if (user) {
      const [limits] = await Promise.all([
        Api.Users.Services.getLimits({
          serviceId: SERVICE_UUID,
          userId: user.legacyId,
        }),
        updateAbility(user.id),
      ]);
      commit(mutations.SET_CURRENT_USER, user);
      commit(mutations.SET_LIMITS, limits);
      router.push('/');
      notifyLoggedIn(user);
    }
  },
  [SET_IS_LOADING]: async ({ state, commit }, value) => {
    if (state.isLoading !== Boolean(value)) {
      commit(mutations.SET_IS_LOADING, value);
    }
  },
};

export default actions;
